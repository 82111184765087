import ModalTitle from 'components/dumb/ModalTitle'
import { Tx } from 'models/transactions'
import { closeModal } from 'models/modal'
import { ReactComponent as SuccessIcon } from 'resources/images/icons/success.svg'
import { getTxExplorerUrl } from 'utils'
import Button from './Button'

interface Props {
  tx: Tx
}

export default function TxSuccessModal({ tx }: Props) {
  return (
    <div className="flex flex-grow flex-col items-center">
      <SuccessIcon className="mb-5 h-12 w-12" />
      <ModalTitle>Transaction submitted</ModalTitle>
      <a
        target="_blank"
        rel="noreferrer"
        className="mb-10 text-center text-lg leading-[1.3] text-red"
        href={getTxExplorerUrl(tx.hash)}
      >
        View on explorer
      </a>
      <Button className="w-full" onClick={() => closeModal()}>
        Close
      </Button>
    </div>
  )
}

import { createEvent, createStore } from 'effector'

export enum ModalState {
  TxWait,
  TxSuccess,
  TxFailure,
  TxRejected,
  Closed,
  ConfirmSwap,
  RouteDetails,
}

export const openTxWaitModal = createEvent()
export const openTxSuccessModal = createEvent()
export const openConfirmSwapModal = createEvent()
export const openTxFailModal = createEvent<any>()
export const openTxRejectModal = createEvent()
export const closeModal = createEvent()
export const openRouteDetailsModal = createEvent()

export const $modalState = createStore<ModalState>(ModalState.Closed)

import { RouteResponse, Route } from 'gql'
import numeral from 'numeral'
import { toDecimal } from 'utils/numbers'

import { TokenType } from './types'

export class RouteProxy {
  raw: RouteResponse | null | undefined
  baseToken: string
  quoteToken: string
  baseTokenAddress: string
  quoteTokenAddress: string
  routes: Route[]
  slippage: string
  priceImpact: string

  constructor(
    route: RouteResponse | null | undefined,
    { baseToken, quoteToken }: { baseToken: TokenType; quoteToken: TokenType }
  ) {
    this.raw = route

    this.baseToken = baseToken.symbol
    this.quoteToken = quoteToken.symbol

    this.baseTokenAddress = route?.baseTokenAddress ?? ''
    this.quoteTokenAddress = route?.quoteTokenAddress ?? ''

    this.routes = route?.routes ?? []

    this.slippage = toDecimal(route?.slippageTolerance).mul(100).toString()
    this.priceImpact = route?.priceImpact
      ? toDecimal(route.priceImpact).mul(100).toFixed(2)
      : ''
  }

  public get sellPrice() {
    if (!this.raw) {
      return { sellPriceLeft: '', sellPriceRight: '', sellPriceUSD: '' }
    }
    const sellPriceRightFormatted = numeral(this.raw?.sellPrice).format(
      '0,.[000000]'
    )
    const sellPriceUSDFormatted = numeral(this.raw?.sellPriceUSD).format(
      '0,.[000000]'
    )
    const sellPriceLeft = `1 ${this.baseToken} @`
    const sellPriceRight = `${
      sellPriceRightFormatted === 'NaN' ? '< 0.000001' : sellPriceRightFormatted
    } ${this.quoteToken}`
    const sellPriceUSD =
      sellPriceUSDFormatted === 'NaN'
        ? '< $0.000001'
        : `≈$${sellPriceUSDFormatted}`

    return { sellPriceLeft, sellPriceRight, sellPriceUSD }
  }

  public get buyPrice() {
    if (!this.raw) {
      return { buyPriceLeft: '', buyPriceRight: '', buyPriceUSD: '' }
    }

    const buyPriceRightFormatted = numeral(this.raw?.sellPrice).format(
      '0,.[000000]'
    )
    const buyPriceUSDFormatted = numeral(this.raw?.sellPriceUSD).format(
      '0,.[000000]'
    )

    const buyPriceLeft = `1 ${this.quoteToken} @`
    const buyPriceRight = `${
      buyPriceRightFormatted === 'NaN' ? '< 0.000001' : buyPriceRightFormatted
    } ${this.baseToken}`
    const buyPriceUSD =
      buyPriceUSDFormatted === 'NaN'
        ? '< $0.000001'
        : `≈$${buyPriceUSDFormatted}`
    return { buyPriceLeft, buyPriceRight, buyPriceUSD }
  }

  public get networkFee() {
    if (!this.raw) {
      return ''
    }
    const networkFee = `${numeral(this.raw?.networkFee).format(
      '0,.[000000]'
    )} milkADA`

    return networkFee
  }

  public get networkFeeUSD() {
    if (!this.raw) {
      return ''
    }
    const networkFeeUSD = `≈$${numeral(this.raw?.networkFeeUSD).format(
      '0,.[000000]'
    )}`

    return networkFeeUSD
  }

  public get inputFormatted() {
    return numeral(this.raw?.volume).format('0,.[000000]')
  }

  public get outputFormatted() {
    return numeral(this.raw?.outputAmount).format('0,.[000000]')
  }

  public get isPriceImpactTooHigh() {
    return toDecimal(this.priceImpact).greaterThanOrEqualTo(5)
  }
}

import classNames from 'classnames'
import { useStore } from 'effector-react'
import { $route } from 'models/swap'
import { SwapFee } from 'models/swap/estimateSwapFee'
import { useState } from 'react'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-thin.svg'

type Props = {
  estimatedFee: SwapFee | null
}

export default function DetailsAndFeesBlock({ estimatedFee }: Props) {
  const [open, setOpen] = useState(false)

  const route = useStore($route)

  return (
    <div className="mt-4">
      <div className="flex">
        <div
          className="flex cursor-pointer items-center"
          id="details-btn"
          onClick={() => setOpen(!open)}
        >
          <div className="mr-2 select-none text-sm leading-5 text-white">
            Details & Fees
          </div>
          <ChevronIcon
            className={classNames(
              'fill-current text-white',
              open ? '' : 'rotate-180'
            )}
          />
        </div>
      </div>
      <div
        className={classNames(
          ' max-h-0 overflow-hidden transition-height duration-700',
          {
            'max-h-[700px]': open,
          }
        )}
      >
        <div className="mt-4 flex h-full flex-col space-y-3 rounded bg-bgDark p-5 text-sm leading-[1.2] text-grayLight">
          <div className="flex items-start justify-between">
            <span>Sell price</span>
            <div className="flex flex-col items-end space-y-[0.2rem]">
              <span id="swap-sell-price-result-left">
                {route?.sellPrice.sellPriceLeft}{' '}
                <span id="swap-sell-price-result-right" className="text-white">
                  {route?.sellPrice.sellPriceRight}
                </span>
              </span>
              <span
                id="swap-sell-price-result-usd"
                className={classNames(
                  route?.isPriceImpactTooHigh && 'text-red'
                )}
              >
                {route?.sellPrice.sellPriceUSD}
              </span>
            </div>
          </div>
          <div className="flex items-start justify-between">
            <span>Buy price</span>
            <div className="flex flex-col items-end space-y-[0.2rem]">
              <span id="swap-buy-price-result-left">
                {route?.buyPrice.buyPriceLeft}{' '}
                <span id="swap-buy-price-result-right" className="text-white">
                  {route?.buyPrice.buyPriceRight}
                </span>
              </span>
              <span
                id="swap-buy-price-result-usd"
                className={classNames(
                  route?.isPriceImpactTooHigh && 'text-red'
                )}
              >
                {route?.buyPrice.buyPriceUSD}
              </span>
            </div>
          </div>
          <div className="flex items-start justify-between">
            <span>Price impact</span>
            <div className="flex flex-col items-end space-y-[0.2rem]">
              <span
                id="swap-price-impact"
                className={classNames(
                  'text-white',
                  route?.isPriceImpactTooHigh && 'text-red'
                )}
              >
                {route?.priceImpact ? `${route.priceImpact}%` : ''}
              </span>
            </div>
          </div>
          <div className="flex items-start justify-between">
            <span>Network fee</span>
            <div className="flex flex-col items-end space-y-[0.2rem]">
              <span className="text-white" id="swap-network-fee">
                {estimatedFee?.fee.toSignificantDigits(6).toString()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

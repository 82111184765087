import Button from 'components/dumb/Button'
import classNames from 'classnames'
import { ReactComponent as MetamaskIcon } from 'resources/images/wallets/metamask.svg'
import { ReactComponent as WalletOkIcon } from 'resources/images/wallets/wallet-ok.svg'
import { ReactComponent as WrongNetworkIcon } from 'resources/images/icons/exclam.svg'
import { useStore } from 'effector-react'
import {
  $connectionState,
  $networkIsOk,
  $shortAddress,
  $connected,
  connectWallet,
} from 'models/wallet'
import { Tooltip } from 'components/dumb/Tooltip'

type Props = { className?: string; type?: 'primary' | 'secondary' }

export default function SmartWalletButton({
  className,
  type = 'secondary',
}: Props) {
  const shortAddress = useStore($shortAddress)
  const networkIsOk = useStore($networkIsOk)
  const connectionState = useStore($connectionState)
  const connected = useStore($connected)

  let content: React.ReactNode = null
  switch (connectionState) {
    case 'connected':
      if (networkIsOk) {
        content = (
          <div className="inline-flex items-center space-x-5">
            <div>{shortAddress}</div>
            <WalletOkIcon className="h-5 w-5 shrink-0" />
          </div>
        )
      } else {
        content = (
          <div className="inline-flex items-center space-x-5">
            <div>{shortAddress}</div>
            <div className="text-yellow">Wrong Network</div>
            <WrongNetworkIcon className="h-5 w-5 shrink-0" />
          </div>
        )
      }
      break
    default:
      content = (
        <div className="inline-flex items-center space-x-5">
          <div>Connect MetaMask</div>
          <MetamaskIcon className="h-5 w-5 shrink-0" />
        </div>
      )
      break
  }

  return (
    <Tooltip label="Disconnect" hide={!connected || !networkIsOk}>
      <Button
        id="connect-btn"
        type={type}
        className={classNames(
          'text-base leading-5 text-white',
          {
            'bg-yellow/6 hover:border-yellow/25 active:bg-yellow/25':
              !networkIsOk && connected,
          },
          className
        )}
        onClick={() => connectWallet()}
      >
        {content}
      </Button>
    </Tooltip>
  )
}

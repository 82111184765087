import React from 'react'
import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-down.svg'
import { noop } from 'utils'

type Props = {
  className?: string
  children: React.ReactNode
  items: {
    text: string
    icon?: React.ReactElement
    onClick?(): void
    disabled?: boolean
  }[]
}

export default function Dropdown({ children, items, className = '' }: Props) {
  const base =
    'text-grayLight text-base font-thin leading-none cursor-pointer relative'

  return (
    <div className="relative z-20">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              className={classNames(
                base,
                `flex w-full items-center justify-between space-x-2 ${
                  open ? 'bg-pinkHover relative z-20' : ''
                }`
              )}
            >
              <div className="border border-transparent text-left text-base">
                {children}
              </div>
              <div>
                <ChevronIcon
                  className={`text-blackSecondary h-[0.6rem] w-[0.6rem] fill-current ${
                    open ? 'rotate-180' : ''
                  } transform cursor-pointer`}
                />
              </div>
            </Menu.Button>
            {items.length > 0 ? (
              <Menu.Items
                className={classNames(
                  className,
                  `absolute top-9 z-10 flex w-full flex-col rounded-md border border-border bg-bgDark py-2`
                )}
              >
                {items.map(({ text, icon, onClick = noop }) => (
                  <Menu.Item
                    key={text}
                    className={`cursor-pointer text-base leading-none text-white `}
                    as="div"
                    onClick={onClick}
                  >
                    <div className="flex items-center space-x-[1rem] py-2 px-4 hover:opacity-50">
                      {icon
                        ? React.cloneElement(icon, { className: 'shrink-0' })
                        : null}
                      <span>{text}</span>
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            ) : null}
          </>
        )}
      </Menu>
    </div>
  )
}

export const TOKEN_ABI = `
[
  {
      "constant": true,
      "inputs": [],
      "name": "name",
      "outputs": [
	  {
	      "name": "",
	      "type": "string"
	  }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
  },
  {
      "constant": false,
      "inputs": [
	  {
	      "name": "_spender",
	      "type": "address"
	  },
	  {
	      "name": "_value",
	      "type": "uint256"
	  }
      ],
      "name": "approve",
      "outputs": [
	  {
	      "name": "",
	      "type": "bool"
	  }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "constant": true,
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
	  {
	      "name": "",
	      "type": "uint256"
	  }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
  },
  {
      "constant": false,
      "inputs": [
	  {
	      "name": "_from",
	      "type": "address"
	  },
	  {
	      "name": "_to",
	      "type": "address"
	  },
	  {
	      "name": "_value",
	      "type": "uint256"
	  }
      ],
      "name": "transferFrom",
      "outputs": [
	  {
	      "name": "",
	      "type": "bool"
	  }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "constant": true,
      "inputs": [],
      "name": "decimals",
      "outputs": [
	  {
	      "name": "",
	      "type": "uint8"
	  }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
  },
  {
      "constant": true,
      "inputs": [
	  {
	      "name": "_owner",
	      "type": "address"
	  }
      ],
      "name": "balanceOf",
      "outputs": [
	  {
	      "name": "balance",
	      "type": "uint256"
	  }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
  },
  {
      "constant": true,
      "inputs": [],
      "name": "symbol",
      "outputs": [
	  {
	      "name": "",
	      "type": "string"
	  }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
  },
  {
      "constant": false,
      "inputs": [
	  {
	      "name": "_to",
	      "type": "address"
	  },
	  {
	      "name": "_value",
	      "type": "uint256"
	  }
      ],
      "name": "transfer",
      "outputs": [
	  {
	      "name": "",
	      "type": "bool"
	  }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "constant": true,
      "inputs": [
	  {
	      "name": "_owner",
	      "type": "address"
	  },
	  {
	      "name": "_spender",
	      "type": "address"
	  }
      ],
      "name": "allowance",
      "outputs": [
	  {
	      "name": "",
	      "type": "uint256"
	  }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
  },
  {
      "payable": true,
      "stateMutability": "payable",
      "type": "fallback"
  },
  {
      "anonymous": false,
      "inputs": [
	  {
	      "indexed": true,
	      "name": "owner",
	      "type": "address"
	  },
	  {
	      "indexed": true,
	      "name": "spender",
	      "type": "address"
	  },
	  {
	      "indexed": false,
	      "name": "value",
	      "type": "uint256"
	  }
      ],
      "name": "Approval",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
	  {
	      "indexed": true,
	      "name": "from",
	      "type": "address"
	  },
	  {
	      "indexed": true,
	      "name": "to",
	      "type": "address"
	  },
	  {
	      "indexed": false,
	      "name": "value",
	      "type": "uint256"
	  }
      ],
      "name": "Transfer",
      "type": "event"
  }
]`

export const CONTRACT_ABI = `
[
    {
        "type": "event",
        "name": "Swapped",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address",
                "indexed": true
            },
            {
                "type": "address",
                "name": "baseToken",
                "internalType": "address",
                "indexed": true
            },
            {
                "type": "address",
                "name": "quoteToken",
                "internalType": "address",
                "indexed": true
            },
            {
                "type": "uint256",
                "name": "amountBase",
                "internalType": "uint256",
                "indexed": false
            },
            {
                "type": "uint256",
                "name": "amountQuote",
                "internalType": "uint256",
                "indexed": false
            }
        ],
        "anonymous": false
    },
    {
        "type": "fallback",
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "stateMutability": "view",
        "outputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "address"
            }
        ],
        "name": "getStorageAddr",
        "inputs": []
    },
    {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "initialize",
        "inputs": [
            {
                "type": "address",
                "name": "_storage",
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "_weth",
                "internalType": "address payable"
            }
        ]
    },
    {
        "type": "function",
        "stateMutability": "view",
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "name": "initialized",
        "inputs": []
    },
    {
        "type": "function",
        "stateMutability": "payable",
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "name": "swap",
        "inputs": [
            {
                "type": "tuple",
                "name": "swapDesc",
                "internalType": "struct Main.SwapDescription",
                "components": [
                    {
                        "type": "address",
                        "name": "baseToken",
                        "internalType": "address"
                    },
                    {
                        "type": "address",
                        "name": "quoteToken",
                        "internalType": "address"
                    },
                    {
                        "type": "uint256",
                        "name": "volume",
                        "internalType": "uint256"
                    },
                    {
                        "type": "uint256",
                        "name": "minimumReturns",
                        "internalType": "uint256"
                    },
                    {
                        "type": "tuple[]",
                        "name": "routes",
                        "internalType": "struct Main.Route[]",
                        "components": [
                            {
                                "type": "uint256",
                                "name": "portion",
                                "internalType": "uint256"
                            },
                            {
                                "type": "tuple[]",
                                "name": "steps",
                                "internalType": "struct Main.Step[]",
                                "components": [
                                    {
                                        "type": "address",
                                        "name": "quoteToken",
                                        "internalType": "address"
                                    },
                                    {
                                        "type": "tuple[]",
                                        "name": "pools",
                                        "internalType": "struct Main.PoolDescription[]",
                                        "components": [
                                            {
                                                "type": "uint8",
                                                "name": "dexType",
                                                "internalType": "enum DEX"
                                            },
                                            {
                                                "type": "address",
                                                "name": "poolAddr",
                                                "internalType": "address"
                                            },
                                            {
                                                "type": "uint256",
                                                "name": "portion",
                                                "internalType": "uint256"
                                            },
                                            {
                                                "type": "uint256",
                                                "name": "minReturns",
                                                "internalType": "uint256"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "receive",
        "stateMutability": "payable"
    }
]`

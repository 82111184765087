import {
  $modalState,
  closeModal,
  ModalState,
  openTxFailModal,
  openTxSuccessModal,
  openTxWaitModal,
  openTxRejectModal,
  openRouteDetailsModal,
  openConfirmSwapModal,
} from '.'

$modalState
  .on(openTxFailModal, () => ModalState.TxFailure)
  .on(openTxSuccessModal, () => ModalState.TxSuccess)
  .on(openTxWaitModal, () => ModalState.TxWait)
  .on(openTxRejectModal, () => ModalState.TxRejected)
  .on(openConfirmSwapModal, () => ModalState.ConfirmSwap)
  .on(closeModal, () => ModalState.Closed)
  .on(openRouteDetailsModal, () => ModalState.RouteDetails)

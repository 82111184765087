import Decimal from 'decimal.js'
import { ethers } from 'ethers'

function toDecimal(n: any) {
  try {
    return new Decimal(n ?? '0')
  } catch (error) {
    return new Decimal(0)
  }
}

const convertHexToNumber = (hex: string, decimals = 18) => {
  if (!hex) return '0'
  //console.log(`Converting to number ${hex} with ${decimals} decimals`)
  return ethers.utils.formatUnits(hex, decimals)
}

export { toDecimal, convertHexToNumber }

import { useLayoutEffect, useCallback, useState } from 'react'
import noop from 'lodash/noop'

export default function useRect(
  ref: React.RefObject<HTMLElement>,
  trigger?: boolean
) {
  const [rect, setRect] = useState(getRect(ref ? ref.current : null))

  const handleResize = useCallback(() => {
    if (!ref.current || !trigger) {
      return
    }

    // Update client rect
    setRect(getRect(ref.current))
  }, [ref, trigger])

  useLayoutEffect(() => {
    if (!ref || !trigger) {
      return
    }
    const element = ref.current
    if (!element) {
      return
    }

    handleResize()

    //@ts-ignore
    if (typeof ResizeObserver === 'function') {
      //@ts-ignore
      let resizeObserver = new ResizeObserver(() => handleResize())
      resizeObserver.observe(element)

      return () => {
        if (!resizeObserver) {
          return
        }

        resizeObserver.disconnect()
        //@ts-ignore
        resizeObserver = null
      }
    } else {
      // Browser support, remove freely
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [handleResize, trigger, ref])

  return rect
}

function getRect(element: HTMLElement | null) {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
      toJSON: noop,
    }
  }

  return element.getBoundingClientRect()
}

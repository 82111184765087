import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
}

export default function ModalTitle({ children, className = '' }: Props) {
  return (
    <p
      className={`text-white ${
        className ?? ''
      } mb-5 text-center text-[1.75rem] leading-none`}
    >
      {children}
    </p>
  )
}

import { useStore } from 'effector-react'
import { $tokens } from 'models/swap'
import { SwapFee } from 'models/swap/estimateSwapFee'
import { TokenType } from 'models/types'
import React from 'react'
import { toDecimal } from 'utils/numbers'

interface Options {
  input: string
  connected: boolean
  networkIsOk: boolean
  fee: SwapFee | null
  feeError: string | null
  loading: boolean
  loadingBalances: boolean
  approved: boolean
  baseTokenBalance?: string
  baseTokenSymbol?: string
  routeError?: boolean
  isPriceImpactTooHigh: boolean
}

export const useButtonValidation = ({
  fee,
  feeError,
  input,
  connected,
  networkIsOk,
  loading,
  loadingBalances,
  approved,
  routeError = false,
  baseTokenBalance = '0',
  baseTokenSymbol = '',
  isPriceImpactTooHigh,
}: Options) => {
  const tokens = useStore($tokens)
  const inputDec = toDecimal(input)

  const milkADA = tokens.find((t) => t.address === '0') as TokenType

  const notEnoughForPayToFee =
    fee &&
    (toDecimal(milkADA.balance).lessThan(fee.fee) ||
      (baseTokenSymbol === milkADA.symbol &&
        inputDec.plus(fee.fee).greaterThan(baseTokenBalance)))

  const buttonDisabledConnected =
    inputDec.lte(0) ||
    !connected ||
    loading ||
    feeError?.includes('Upfront') ||
    inputDec.greaterThan(baseTokenBalance) ||
    notEnoughForPayToFee ||
    inputDec.isNaN()

  const buttonDisabled = connected ? buttonDisabledConnected : false

  const buttonText = React.useMemo(() => {
    if (!connected) {
      return 'Connect MetaMask'
    }

    if (!networkIsOk) {
      return 'Wrong network'
    }

    if (loadingBalances) {
      return 'Loading...'
    }

    if (feeError?.includes('Upfront')) {
      return `No milkADA to pay network fee`
    }

    if (routeError) {
      return 'No route found'
    }

    if (inputDec.eq(0)) {
      return 'Enter an amount'
    }

    if (inputDec.greaterThan(baseTokenBalance)) {
      return `Insufficient ${baseTokenSymbol} balance`
    }

    if (notEnoughForPayToFee) {
      return `No milkADA to pay network fee`
    }

    if (loading) {
      return 'Loading...'
    }

    if (!approved) {
      return 'Approve'
    }

    if (isPriceImpactTooHigh) {
      return 'Swap Anyway'
    }

    return 'Swap'
  }, [
    baseTokenSymbol,
    loading,
    feeError,
    loadingBalances,
    baseTokenBalance,
    connected,
    inputDec,
    approved,
    routeError,
    notEnoughForPayToFee,
    isPriceImpactTooHigh,
    networkIsOk,
  ])

  return {
    buttonDisabled,
    buttonText,
  }
}

import TextLink from 'components/dumb/TextLink'
import { Tx } from 'models/transactions'
import { provider } from 'models/wallet'
import { useEffect, useState } from 'react'
import toast, { Toast } from 'react-hot-toast'
import { ReactComponent as CloseIcon } from 'resources/images/icons/tx-close.svg'
import { ReactComponent as ConfirmedIcon } from 'resources/images/icons/tx-confirmed.svg'
import { ReactComponent as FailedIcon } from 'resources/images/icons/tx-failed.svg'
import { ReactComponent as SubmittedIcon } from 'resources/images/icons/tx-submitted.svg'
import invariant from 'tiny-invariant'
import { getTxExplorerUrl } from 'utils'

type Props = {
  t: Toast
  tx: Tx
}

export default function TxToast({ t, tx }: Props) {
  const [status, setStatus] = useState(tx.status)

  useEffect(() => {
    const getTxStatus = async (hash: string) => {
      invariant(provider, 'web3 provider not found')

      if (status === 'confirmed' || status === 'failed') return

      const receipt = await provider.getTransactionReceipt(hash)
      if (receipt) {
        const updatedStatus = receipt.status === 1 ? 'confirmed' : 'failed'
        setStatus(updatedStatus)
        setTimeout(() => toast.dismiss(t.id), 4000)
      }
    }

    const interval = setInterval(() => getTxStatus(tx.hash), 2000)

    return () => clearInterval(interval)
  }, [tx.hash, status, t])

  let label = ''
  let icon = null
  switch (status) {
    case 'confirmed':
      icon = <ConfirmedIcon className="h-[1rem] w-[1rem]" />
      label = 'Transaction successful'
      break
    case 'failed':
      icon = <FailedIcon className="h-[1rem] w-[1rem]" />
      label = 'Transaction failed'
      break
    case 'submitted':
      label = 'Transaction submitted'
      icon = <SubmittedIcon className="h-[1rem] w-[1rem]" />
      break
    default:
      // icon = <PendingIcon className="w-5 h-5 animate-spin" />
      break
  }

  return (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } pointer-events-auto flex w-[18.75rem] rounded-[0.375rem] border border-border bg-bgDark`}
    >
      <div className="w-0 flex-1 p-[1rem]">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">{icon}</div>
          <div className="ml-[0.75rem] flex-1">
            <p className="mb-[0.3rem] text-[0.875rem] font-light text-white">
              {label}
            </p>
            <p className="text-[0.875rem] font-light text-white">
              {tx.msg ?? ''}
            </p>
            <TextLink
              className="text-[0.81rem]"
              href={getTxExplorerUrl(tx.hash)}
            >
              View on explorer
            </TextLink>
          </div>
        </div>
      </div>

      <CloseIcon
        className="m-4 h-[1rem] w-[1rem] cursor-pointer"
        onClick={() => {
          toast.dismiss(t.id)
        }}
      />
    </div>
  )
}

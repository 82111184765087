import Button from 'components/dumb/Button'
import ModalTitle from 'components/dumb/ModalTitle'
import { Tooltip } from 'components/dumb/Tooltip'
import { useStore } from 'effector-react'
import { $estimatedFee, $route, swapFx } from 'models/swap'
import numeral from 'numeral'
import { ReactComponent as ArrowDownIcon } from 'resources/images/icons/arrow-down.svg'

export default function SmartConfirmSwapModal() {
  const route = useStore($route)
  const estimatedFee = useStore($estimatedFee)

  if (!route) {
    return null
  }

  return (
    <div className="flex flex-grow flex-col items-center">
      <ModalTitle>Swap confirmation</ModalTitle>
      <div className="flex flex-col items-center">
        <div className="flex items-center text-[2.25rem] leading-[2.8rem]">
          <span className="mr-2 text-white">{route.inputFormatted}</span>
          <span className="text-red">{route?.baseToken}</span>
        </div>
        <ArrowDownIcon className="my-1 h-5 w-5" />
        <div className="mb-5 flex items-center text-[2.25rem] leading-[2.8rem]">
          <span className="mr-2 text-white">{route.outputFormatted}</span>
          <span className="text-red">{route?.quoteToken}</span>
        </div>
      </div>

      <div className="mb-10 flex w-full flex-col space-y-3 rounded bg-black/15 p-5">
        <Param
          name="Slippage tolerance"
          tooltip="Your transaction will revert if the price changes unfavorably by more than this percentage."
          value={<span>{route.slippage}%</span>}
        />
        <Param
          name="Price impact"
          tooltip="The difference between the market price and estimated price due to trade size."
          value={<span>{route.priceImpact}%</span>}
        />
        <Param
          name="Minimum received"
          tooltip="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
          value={
            <span>{`${numeral(route?.raw?.minReturnAmount).format(
              '0,.[000000]'
            )} ${route.quoteToken}`}</span>
          }
        />
        <Param
          name="Sell Price"
          value={
            <TwoColValue
              left={route.sellPrice.sellPriceLeft}
              right={route.sellPrice.sellPriceRight}
              bottom={route.sellPrice.sellPriceUSD}
            />
          }
        />
        <Param
          name="Buy Price"
          value={
            <TwoColValue
              left={route.buyPrice.buyPriceLeft}
              right={route.buyPrice.buyPriceRight}
              bottom={route.buyPrice.buyPriceUSD}
            />
          }
        />
        <Param
          name="Network fee"
          value={
            <TwoColValue
              right={estimatedFee?.fee.toSignificantDigits(6).toString()}
              bottom={''}
            />
          }
        />
      </div>
      <Button className="w-full" onClick={() => swapFx()}>
        Confirm swap
      </Button>
    </div>
  )
}

interface ParamProps {
  name: string
  value: React.ReactNode
  tooltip?: string
}

const Param = ({ name, value, tooltip }: ParamProps) => {
  return (
    <div className="flex w-full items-center justify-between text-sm leading-[1.3] text-white">
      <div className="flex items-center">
        <span className="text-grayLight">{name}</span>
        {tooltip ? <Tooltip label={tooltip} placement="right" /> : null}
      </div>
      {value}
    </div>
  )
}

interface TwoColValueProps {
  left?: string
  right?: React.ReactNode
  bottom?: React.ReactNode
}

const TwoColValue = ({ left, right, bottom }: TwoColValueProps) => (
  <div className="text-right">
    <div className="flex items-center">
      <span className="mr-1 text-grayLight">{left}</span>
      <span>{right}</span>
    </div>
    <span className="text-grayLight">{bottom}</span>
  </div>
)

import { createEffect, createEvent, restore } from 'effector'
import { ethers } from 'ethers'
import invariant from 'tiny-invariant'
import { desiredChain, desiredChainId, shortenAddress } from 'utils'

export type ConnectionState =
  | 'connected'
  | 'notConnected'
  | 'connecting'
  | 'error'

export const provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum, 'any')
  : null

export const logout = createEvent()
export const setConnectionState = createEvent<ConnectionState>()
export const connectWallet = createEvent()
export const setNetworkIsOk = createEvent<boolean>()
export const setAddress = createEvent<string>()

export const $address = restore(setAddress, '')
export const $connectionState = restore(setConnectionState, 'notConnected')
export const $networkIsOk = restore(setNetworkIsOk, false)
export const $shortAddress = $address.map(shortenAddress)

export const $connected = $connectionState.map(
  (connectionState) => connectionState === 'connected'
)

export const metamaskAuthFx = createEffect(async () => {
  if (!window.ethereum?.isMetaMask) {
    window.open('https://metamask.io', '_blank', 'noopener,noreferrer')
  }
  invariant(window.ethereum?.isMetaMask, 'metamask: extension not found')

  // MetaMask "Already processing eth_requestAccounts" workaround
  // https://github.com/MetaMask/metamask-extension/issues/10085

  // const accounts = await window.ethereum?.request({
  //   method: 'eth_requestAccounts',
  // })

  const permissions = await window.ethereum?.request({
    method: 'wallet_requestPermissions',
    params: [{ eth_accounts: {} }],
  })

  const account = permissions[0]?.caveats[0]?.value[0]
  invariant(account, 'metamask: account not found')

  return { address: account }
})

export const switchNetworkFx = createEffect(async () => {
  await window.ethereum?.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: desiredChainId }],
  })
})

export const addNetworkFx = createEffect(async () => {
  await window.ethereum?.request({
    method: 'wallet_addEthereumChain',
    params: [desiredChain],
  })
})

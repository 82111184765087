import { ReactComponent as HeaderLogo } from 'resources/images/header-logo.svg'
import { ReactComponent as TelegramIcon } from 'resources/images/icons/telegram.svg'
import { ReactComponent as TwitterIcon } from 'resources/images/icons/twitter.svg'
import { ReactComponent as DiscordIcon } from 'resources/images/icons/discord.svg'
import Dropdown from 'components/dumb/Dropdown'
import SmartWalletButton from './SmartWalletButton'
import SmartHistory from '../SmartHistory'
import { openUrl } from 'utils'

export default function Header() {
  const dropdownItems = [
    {
      text: 'Telegram',
      icon: <TelegramIcon />,
      onClick: () => openUrl('https://t.me/defire_fi'),
    },
    {
      text: 'Twitter',
      icon: <TwitterIcon />,
      onClick: () => openUrl('https://twitter.com/DeFIRE_Fi'),
    },
    {
      text: 'Discord',
      icon: <DiscordIcon />,
      onClick: () => openUrl('https://discord.gg/nqPjrfy5HF'),
    },
    // {
    //   text: 'Reddit',
    //   icon: <RedditIcon />,
    //   onClick: () => openUrl('https://www.reddit.com/r/deFIRE_fi/'),
    // },
  ]

  return (
    <div className="relative mt-8 flex w-full justify-between">
      <div className="flex items-center">
        <a
          className="mr-20 h-[38px] w-[230px]"
          href="https://defire.fi"
          target="_blank"
          rel="noreferrer"
        >
          <HeaderLogo />
        </a>
        <div className="text-4 mr-10 font-light leading-none text-white">
          Swap
        </div>
        <Dropdown items={dropdownItems} className="w-[9rem]">
          Contact Us
        </Dropdown>
      </div>
      <div className="items-centers flex space-x-4 ">
        <SmartHistory />
        <SmartWalletButton />
      </div>
    </div>
  )
}

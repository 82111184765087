import { ethers } from 'ethers'
import { RouteResponse } from 'gql'
import { RouteProxy } from 'models/routeProxy'
import { TokenType } from 'models/types'
import { provider } from 'models/wallet'
import invariant from 'tiny-invariant'
import { calculateGasMargin } from 'utils/calcGasMargin'
import { toDecimal } from 'utils/numbers'
import { parseNumber } from 'utils/parseNumber'
import { CONTRACT_ABI } from './abi'

export const buildSwapArgs = (
  baseToken: TokenType,
  quoteToken: TokenType,
  route: RouteProxy | RouteResponse
) => {
  const preparedRoute =
    route instanceof RouteProxy
      ? route
      : new RouteProxy(route, {
          baseToken,
          quoteToken,
        })

  invariant(provider, 'web3 provider not found')
  invariant(preparedRoute.raw, 'route not found')

  const baseTokenAddress =
    baseToken.address === '0' ? ethers.constants.AddressZero : baseToken.address
  const quoteTokenAddress =
    quoteToken.address === '0'
      ? ethers.constants.AddressZero
      : quoteToken.address

  const args = {
    baseToken: baseTokenAddress,
    quoteToken: quoteTokenAddress,
    volume: preparedRoute.raw.volumeDec,
    minimumReturns: preparedRoute.raw.minReturnAmountDec,
    routes: preparedRoute.routes.map((r) => ({
      portion: ethers.utils.parseEther(r.portion.toString()),
      steps: r.steps.map((s) => ({
        quoteToken: s.quoteTokenAddress,
        pools: s.pools.map((pool) => ({
          dexType: pool.dexType,
          poolAddr: pool.poolAddress,
          portion: ethers.utils.parseEther(pool.portion.toString()),
          minReturns: pool.minReturnAmountDec,
        })),
      })),
    })),
  }

  return args
}

export type SwapArgs = ReturnType<typeof buildSwapArgs>

export const estimateSwapFee = async (args: SwapArgs) => {
  const contractAddr = process.env.REACT_APP_CONTRACT_ADDRESS ?? ''
  invariant(provider, 'web3 is not defined')

  const signer = provider.getSigner()
  const contract = new ethers.Contract(contractAddr, CONTRACT_ABI, signer)
  const volume =
    args.baseToken === ethers.constants.AddressZero ? args.volume : undefined

  const { gasPrice } = await provider.getFeeData()

  let estGasLimit = ethers.BigNumber.from(1_000_000)
  try {
    estGasLimit = await contract.estimateGas.swap(args, {
      gasPrice,
      value:
        args.baseToken === ethers.constants.AddressZero
          ? args.volume
          : undefined,
    })
  } catch (error) {}

  const gasLimit = calculateGasMargin(estGasLimit)

  const gasPriceD = toDecimal(gasPrice?.toString()).div(toDecimal(10).pow(9))
  const gasLimitD = toDecimal(gasLimit.toString()).div(toDecimal(10).pow(9))

  return {
    fee: gasPriceD.mul(gasLimitD),
    gasPriceD,
    gasLimitD,
    gasLimit,
    gasPrice,
    value: volume,
  }
}

export type SwapFee = Awaited<ReturnType<typeof estimateSwapFee>>

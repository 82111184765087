import { useStore } from 'effector-react'
import { ReactComponent as CloseModalIcon } from 'resources/images/icons/close.svg'
import { $modalState, closeModal, ModalState } from 'models/modal'
import { ReactNode, useLayoutEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import useRect from 'utils/hooks/useRect'
import useResize from 'utils/hooks/useResize'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import classNames from 'classnames'

interface Props {
  children: ReactNode
  className?: string
}

const portal = document.getElementById('modal')
const root = document.getElementById('root')

export default function ModalContainer({ children, className = '' }: Props) {
  const modalRef = useRef<HTMLDivElement>(null)
  const showModal = useStore($modalState.map((ms) => ms !== ModalState.Closed))

  useOutsideClick(modalRef, () => {
    if (showModal) {
      closeModal()
    }
  })

  const { height: modalHeight } = useRect(modalRef, showModal)
  const { height: windowHeight } = useResize()

  const alignTop = modalHeight > windowHeight

  useLayoutEffect(() => {
    if (!root) {
      return
    }

    if (showModal) {
      root.classList.add('disable-scroll')
    } else {
      root.classList.remove('disable-scroll')
    }
  }, [showModal])

  if (!portal || !showModal) {
    return null
  }

  const content = (
    <div className="relative z-30 overflow-hidden p-10 pt-11" ref={modalRef}>
      {children}
    </div>
  )

  const container = `
    flex flex-col ${alignTop ? 'justify-start' : 'justify-center'} 
    absolute w-full h-screen z-100 flex flex-col items-center overflow-hidden
  `

  return createPortal(
    <>
      <div className={container}>
        <div
          className={classNames(
            `modal modal-shadow relative z-40 block w-[600px] rounded-md border border-border bg-bgDark`,
            className
          )}
        >
          <div
            className="absolute right-6 top-6 z-40 cursor-pointer"
            onClick={() => closeModal()}
          >
            <CloseModalIcon className="h-4 w-4" />
          </div>
          {content}
        </div>
      </div>
      <div
        className={`
	  fixed z-30 h-screen w-screen bg-dark/80 backdrop-blur-sm backdrop-filter
	  `}
        onClick={() => closeModal()}
      />
    </>,
    portal
  )
}

import { GetRouteQueryVariables } from 'gql'
import { graphqlSdk } from 'gql/client'

class Fetcher {
  static async gql(data: GetRouteQueryVariables) {
    const { getRoute } = await graphqlSdk.GetRoute(data)
    const { __typename, ...result } = getRoute
    return result
  }

  static async get(url: string) {
    const res = await fetch(url, { cache: 'no-cache' })
    return res.json()
  }
}

export default Fetcher

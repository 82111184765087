import { sample } from 'effector'
import differenceBy from 'lodash/differenceBy'
import { persist } from 'effector-storage/local'
import produce from 'immer'
import { logout } from 'models/wallet'
import { toDecimal } from 'utils/numbers'
import {
  $balancesReady,
  $input,
  $loading,
  $swap,
  $tokens,
  fetchBalancesFx,
  fetchRoutesFx,
  fetchTokens,
  fetchTokensFx,
  setBaseToken,
  setDebouncedInput,
  setInput,
  setSlippage,
  setQuoteToken,
  swapTokens,
  $userImportedTokens,
  fetchTokenByAddress,
  $unimportedToken,
  importToken,
  removeToken,
  $routeRaw,
  $routeError,
  $baseToken,
  $quoteToken,
  $route,
  estimateGasFx,
  $estimatedFee,
  $estimateFeeError,
  $estimateFeeLoading,
} from '.'
import invariant from 'tiny-invariant'
import { RouteProxy } from 'models/routeProxy'

persist({ store: $userImportedTokens, key: 'userImportedTokens' })

sample({
  clock: [$input, fetchRoutesFx],
  fn: () => true,
  target: $loading,
})

sample({
  clock: [fetchRoutesFx.fail, fetchRoutesFx.doneData],
  fn: () => false,
  target: $loading,
})

sample({
  clock: [fetchRoutesFx],
  fn: () => '',
  target: $routeError,
})

sample({
  clock: fetchRoutesFx.fail,
  fn: (payload) => {
    console.log(payload)
    return payload.error.message
  },
  target: $routeError,
})

sample({
  clock: fetchRoutesFx.doneData,
  source: $swap,
  fn: (swap, routeRaw) => {
    return {
      ...routeRaw,
      slippageTolerance: toDecimal(swap.slippage).div(100).toNumber(),
    }
  },
  target: $routeRaw,
})

sample({
  clock: [fetchRoutesFx.fail],
  fn: () => null,
  target: $routeRaw,
})

sample({
  clock: $routeRaw,
  source: { baseToken: $baseToken, quoteToken: $quoteToken },
  fn: ({ baseToken, quoteToken }, routeRaw) =>
    new RouteProxy(routeRaw, { baseToken, quoteToken }),
  target: $route,
})

sample({
  clock: fetchTokensFx.doneData,
  source: $userImportedTokens,
  fn: (userImportedTokens, tokens) =>
    produce(tokens, (draft) => {
      const list = draft.concat(userImportedTokens)
      return list.sort((a, b) => (a.symbol < b.symbol ? -1 : 1))
    }),
  target: $tokens,
})

sample({
  clock: fetchTokens,
  target: fetchTokensFx,
})

sample({
  clock: fetchTokenByAddress,
  fn: () => null,
  target: $unimportedToken,
})

sample({
  clock: fetchTokenByAddress.doneData,
  source: $userImportedTokens,
  fn: (_, token) => token,
  filter: (userImportedTokens, token) =>
    token
      ? userImportedTokens.findIndex((t) => t.symbol === token.symbol) === -1
      : false,
  target: $unimportedToken,
})

sample({
  clock: importToken,
  source: {
    unimportedToken: $unimportedToken,
    userImportedTokens: $userImportedTokens,
  },
  fn: ({ unimportedToken, userImportedTokens }) => {
    return unimportedToken &&
      userImportedTokens.findIndex(
        (t) => t.symbol === unimportedToken.symbol
      ) === -1
      ? userImportedTokens.concat(unimportedToken)
      : userImportedTokens
  },
  target: $userImportedTokens,
})

sample({
  clock: $userImportedTokens,
  source: $tokens,
  fn: (tokens, userImportedTokens) =>
    produce(tokens, (draft) => {
      const diff = differenceBy(userImportedTokens, tokens, 'symbol')
      const list = draft.concat(diff)
      return list.sort((a, b) => (toDecimal(a.balance).gt(b.balance) ? -1 : 1))
    }),
  target: $tokens,
})

sample({
  clock: removeToken,
  source: $userImportedTokens,
  fn: (userImportedTokens, token) =>
    userImportedTokens.filter((t) => t.symbol !== token.symbol),
  target: $userImportedTokens,
})

sample({
  clock: removeToken,
  source: $tokens,
  fn: (tokens, token) => tokens.filter((t) => t.symbol !== token.symbol),
  target: $tokens,
})

sample({
  clock: [$tokens, setBaseToken, setQuoteToken],
  source: [$baseToken, $quoteToken],
  filter: ([baseToken, quoteToken]) => {
    return Boolean(baseToken.address && quoteToken.address)
  },
  fn: () => ({}),
  target: estimateGasFx,
})

sample({
  clock: setQuoteToken,
  fn: () => ({}),
  target: fetchRoutesFx,
})

sample({
  clock: $tokens,
  filter: (swap) =>
    swap.baseTokenAddress === null && swap.quoteTokenAddress === null,
  source: $swap,
  fn: (swap) => {
    const baseTokenAddress =
      process.env.REACT_APP_DEFAULT_BASE_TOKEN_ADDRESS ?? ''
    const quoteTokenAddress =
      process.env.REACT_APP_DEFAULT_QUOTE_TOKEN_ADDRESS ?? ''

    return { ...swap, baseTokenAddress, quoteTokenAddress }
  },
  target: $swap,
})

$swap
  .on(setInput, (swap, input) =>
    toDecimal(input).eq(0)
      ? { ...swap, input, inputUSD: '', output: '', outputUSD: '' }
      : { ...swap, input }
  )
  .on(setSlippage, (swap, slippage) => ({ ...swap, slippage }))
  .on(fetchRoutesFx.doneData, (swap, route) => ({
    ...swap,
    inputUSD: route.volumeUSD ?? '',
    output: route.outputAmount ?? '',
    outputUSD: route.outputAmountUSD ?? '',
  }))
  .on(fetchRoutesFx.fail, (swap) => ({
    ...swap,
    inputUSD: '',
    output: '',
    outputUSD: '',
  }))
  .on(setBaseToken, (swap, newBaseToken) =>
    newBaseToken === swap.quoteTokenAddress
      ? {
          ...swap,
          input: '',
          inputUSD: '',
          output: '',
          outputUSD: '',
          baseTokenAddress: swap.quoteTokenAddress,
          quoteTokenAddress: swap.baseTokenAddress,
        }
      : {
          ...swap,
          input: '',
          inputUSD: '',
          output: '',
          outputUSD: '',
          baseTokenAddress: newBaseToken,
        }
  )
  .on(setQuoteToken, (swap, newQuoteToken) =>
    newQuoteToken === swap.baseTokenAddress
      ? {
          ...swap,
          output: '',
          outputUSD: '',
          baseTokenAddress: swap.quoteTokenAddress,
          quoteTokenAddress: swap.baseTokenAddress,
        }
      : {
          ...swap,
          output: '',
          outputUSD: '',
          quoteTokenAddress: newQuoteToken,
        }
  )
  .on(swapTokens, (swap) => ({
    ...swap,
    baseTokenAddress: swap.quoteTokenAddress,
    quoteTokenAddress: swap.baseTokenAddress,
    input: swap.output,
    inputUSD: swap.outputUSD,
    output: swap.input,
    outputUSD: swap.inputUSD,
  }))

sample({
  clock: [setDebouncedInput, swapTokens, setSlippage],
  source: $swap,
  filter: ({ input, baseTokenAddress, quoteTokenAddress }) =>
    toDecimal(input).gt(0) &&
    Boolean(quoteTokenAddress) &&
    Boolean(baseTokenAddress),
  fn: () => ({}),
  target: fetchRoutesFx,
})

sample({
  clock: fetchBalancesFx.doneData,
  source: $balancesReady,
  filter: (b) => b === false,
  fn: () => true,
  target: $balancesReady,
})

sample({
  clock: fetchBalancesFx.doneData,
  source: $tokens,
  fn: (tokens, _tokensWithBalances) =>
    produce(tokens, (draft) => {
      draft.forEach((a, i) => {
        a.balance = _tokensWithBalances[i]?.balance
        a.allowance = _tokensWithBalances[i]?.allowance
      })
      draft.sort((a, b) => (toDecimal(a.balance).gt(b.balance) ? -1 : 1))
    }),
  target: $tokens,
})

sample({
  clock: logout,
  source: $tokens,
  fn: (tokens) =>
    produce(tokens, (draft) => {
      draft.sort((a, b) => (a.symbol < b.symbol ? -1 : 1))
      draft.forEach((a) => {
        a.balance = '0'
        a.allowance = '0'
      })
    }),
  target: $tokens,
})

sample({
  clock: logout,
  fn: () => false,
  target: $balancesReady,
})

// ADA and WADA set empty field effects
sample({
  clock: setBaseToken,
  source: { swap: $swap, tokens: $tokens },
  fn: ({ swap, tokens }, newBaseToken) => {
    const WADA = tokens.find((t) => t.symbol.toLowerCase() === 'wada')
    invariant(WADA, 'WADA not found in tokenlist')

    if (
      (newBaseToken === '0' && swap.quoteTokenAddress === WADA.address) ||
      (newBaseToken === WADA.address && swap.quoteTokenAddress === '0')
    ) {
      return {
        ...swap,
        baseTokenAddress: newBaseToken,
        quoteTokenAddress: '',
      }
    }
    return swap
  },
  target: $swap,
})

sample({
  clock: setQuoteToken,
  source: { swap: $swap, tokens: $tokens },
  fn: ({ swap, tokens }, newQuoteToken) => {
    const WADA = tokens.find((t) => t.symbol.toLowerCase() === 'wada')
    invariant(WADA, 'WADA not found in tokenlist')
    if (
      (newQuoteToken === '0' && swap.baseTokenAddress === WADA.address) ||
      (newQuoteToken === WADA.address && swap.baseTokenAddress === '0')
    ) {
      return {
        ...swap,
        quoteTokenAddress: newQuoteToken,
        baseTokenAddress: '',
      }
    }
    return swap
  },
  target: $swap,
})

$estimatedFee.on(estimateGasFx.doneData, (_, { fee }) => fee)
$estimateFeeError.on(estimateGasFx.failData, (_, error: any) => {
  return error?.data?.message || 'unknown error'
})
$estimateFeeLoading
  .on(estimateGasFx, () => true)
  .reset(estimateGasFx.done, estimateGasFx.fail)

fetchTokens()

// При старте приложения пробуем загрузить данные
setTimeout(fetchBalancesFx, 250)

// Отправляем запросы каждые 5 секунд
//setInterval(fetchADABalanceFx, 5000)
setInterval(fetchBalancesFx, 5000)

import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Address: string
  Decimal: string
}

export type Pool = {
  __typename?: 'Pool'
  dex: Scalars['String']
  dexType: Scalars['Int']
  minReturnAmount: Scalars['Decimal']
  minReturnAmountDec: Scalars['String']
  minReturnAmountUSD?: Maybe<Scalars['Decimal']>
  outputAmount: Scalars['Decimal']
  outputAmountUSD?: Maybe<Scalars['Decimal']>
  pool: Scalars['String']
  poolAddress: Scalars['Address']
  portion: Scalars['Float']
}

export type Query = {
  __typename?: 'Query'
  getRoute: RouteResponse
}

export type QueryGetRouteArgs = {
  request: RouteRequest
}

export type Route = {
  __typename?: 'Route'
  buyPrice: Scalars['Decimal']
  buyPriceUSD: Scalars['Decimal']
  dex: Scalars['String']
  portion: Scalars['Float']
  sellPrice: Scalars['Decimal']
  sellPriceUSD?: Maybe<Scalars['Decimal']>
  steps: Array<Step>
}

export type RouteRequest = {
  baseToken: Scalars['Address']
  quoteToken: Scalars['Address']
  slippage?: InputMaybe<Scalars['Float']>
  volume: Scalars['Decimal']
}

export type RouteResponse = {
  __typename?: 'RouteResponse'
  baseToken: Scalars['String']
  baseTokenAddress: Scalars['Address']
  buyPrice: Scalars['Decimal']
  buyPriceUSD?: Maybe<Scalars['Decimal']>
  minReturnAmount: Scalars['Decimal']
  minReturnAmountDec: Scalars['String']
  minReturnAmountUSD?: Maybe<Scalars['Decimal']>
  networkFee: Scalars['Decimal']
  networkFeeUSD?: Maybe<Scalars['Decimal']>
  outputAmount: Scalars['Decimal']
  outputAmountUSD?: Maybe<Scalars['Decimal']>
  priceImpact: Scalars['Decimal']
  quoteToken: Scalars['String']
  quoteTokenAddress: Scalars['Address']
  routes?: Maybe<Array<Route>>
  sellPrice: Scalars['Decimal']
  sellPriceUSD?: Maybe<Scalars['Decimal']>
  slippageTolerance: Scalars['Float']
  volume: Scalars['Decimal']
  volumeDec: Scalars['String']
  volumeUSD?: Maybe<Scalars['Decimal']>
}

export type Step = {
  __typename?: 'Step'
  pools: Array<Pool>
  quoteToken: Scalars['String']
  quoteTokenAddress: Scalars['Address']
}

export type GetRouteQueryVariables = Exact<{
  baseToken: Scalars['Address']
  quoteToken: Scalars['Address']
  volume: Scalars['Decimal']
  slippage?: InputMaybe<Scalars['Float']>
}>

export type GetRouteQuery = {
  __typename?: 'Query'
  getRoute: {
    __typename?: 'RouteResponse'
    baseToken: string
    baseTokenAddress: string
    quoteToken: string
    quoteTokenAddress: string
    volume: string
    volumeDec: string
    volumeUSD?: string | null
    outputAmount: string
    outputAmountUSD?: string | null
    minReturnAmount: string
    minReturnAmountDec: string
    minReturnAmountUSD?: string | null
    sellPrice: string
    sellPriceUSD?: string | null
    buyPrice: string
    buyPriceUSD?: string | null
    slippageTolerance: number
    priceImpact: string
    networkFee: string
    networkFeeUSD?: string | null
    routes?: Array<{
      __typename?: 'Route'
      dex: string
      portion: number
      sellPrice: string
      sellPriceUSD?: string | null
      buyPrice: string
      buyPriceUSD: string
      steps: Array<{
        __typename?: 'Step'
        quoteToken: string
        quoteTokenAddress: string
        pools: Array<{
          __typename?: 'Pool'
          dexType: number
          dex: string
          pool: string
          poolAddress: string
          portion: number
          outputAmount: string
          outputAmountUSD?: string | null
          minReturnAmount: string
          minReturnAmountDec: string
          minReturnAmountUSD?: string | null
        }>
      }>
    }> | null
  }
}

export const GetRouteDocument = gql`
  query GetRoute(
    $baseToken: Address!
    $quoteToken: Address!
    $volume: Decimal!
    $slippage: Float
  ) {
    getRoute(
      request: {
        baseToken: $baseToken
        quoteToken: $quoteToken
        volume: $volume
        slippage: $slippage
      }
    ) {
      baseToken
      baseTokenAddress
      quoteToken
      quoteTokenAddress
      volume
      volumeDec
      volumeUSD
      outputAmount
      outputAmountUSD
      minReturnAmount
      minReturnAmountDec
      minReturnAmountUSD
      sellPrice
      sellPriceUSD
      buyPrice
      buyPriceUSD
      slippageTolerance
      priceImpact
      networkFee
      networkFeeUSD
      routes {
        dex
        portion
        sellPrice
        sellPriceUSD
        buyPrice
        buyPriceUSD
        steps {
          quoteToken
          quoteTokenAddress
          pools {
            dexType
            dex
            pool
            poolAddress
            portion
            outputAmount
            outputAmountUSD
            minReturnAmount
            minReturnAmountDec
            minReturnAmountUSD
          }
        }
      }
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    GetRoute(
      variables: GetRouteQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetRouteQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetRouteQuery>(GetRouteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetRoute',
        'query'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>

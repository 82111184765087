import { useStore } from 'effector-react'
import numeral from 'numeral'
import { $route } from 'models/swap'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { connectionPath } from './connectionPath'
import { Pool } from 'gql'

export default function SmartRouteDetailsModal() {
  const [paths, setPaths] = useState<{ start: string; end: string }[]>([])

  /*
  const routes = [
    {
      portion: '0.8',
      steps: [
        {
          symbol: 'WETH',
        },
        {
          symbol: 'USDC',
        },
      ],
    },
    {
      portion: '0.2',
      steps: [
        {
          symbol: 'WETH',
        },
        {
          symbol: 'USDC',
        },
        {
          symbol: 'USDCCCC',
        },
      ],
    },
  ]
    */

  const route = useStore($route)
  const routes = route?.routes ?? []

  const refs = useRef(routes.map(() => createRef<HTMLDivElement>()))
  const parentRef = useRef<HTMLDivElement>(null)
  const startRef = useRef<HTMLDivElement>(null)
  const endRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!parentRef.current || !startRef.current || !endRef.current) {
      return
    }

    const parentRect = parentRef.current.getBoundingClientRect()
    const startRect = startRef.current.getBoundingClientRect()
    const endRect = endRef.current.getBoundingClientRect()

    const paths_ = refs.current.map((ref) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect()

        const startFrom = {
          x: startRect.left - parentRect.left + startRect.width + 15,
          y: parentRect.height / 2,
        }

        const startTo = {
          x: rect.left - parentRect.left - 17,
          y: rect.top - parentRect.top + rect.height / 2,
        }

        const endFrom = {
          x: rect.left - parentRect.left + rect.width + 17,
          y: rect.top - parentRect.top + rect.height / 2,
        }

        const endTo = {
          x: endRect.left - parentRect.left - 17,
          y: parentRect.height / 2,
        }

        return {
          start: connectionPath({
            from: startFrom,
            to: startTo,
          }),
          end: connectionPath({ from: endFrom, to: endTo }),
        }
      }
      return { start: '', end: '' }
    })
    setPaths(paths_)
  }, [])

  if (routes.length === 0) {
    return null
  }

  return (
    <div>
      <div className="mb-4 text-sm leading-[1.3] text-white">
        Routing details
      </div>
      <div ref={parentRef} className="relative flex items-center">
        <div ref={startRef} className="mr-[52px] flex items-center">
          <div className="mr-5 h-9 w-9 rounded-full bg-red" />
          <span className="text-base leading-5 text-white">
            {route?.inputFormatted} {route?.baseToken ?? ''}
          </span>
        </div>
        {paths.map((path) => (
          <LineBezier key={path.start} d={path.start} />
        ))}
        <div className="flex grow flex-col space-y-10">
          {routes.map((route, routeIndex) => (
            <div
              className="flex items-center space-x-6"
              key={`route-${routeIndex}`}
              ref={refs.current[routeIndex]}
            >
              <span className="text-xs leading-4 text-white">
                {numeral(route.portion).format('0%')}
              </span>
              {route.steps.map((step, stepIndex) => (
                <React.Fragment key={`step-${stepIndex}`}>
                  <LineStraight />
                  <TokenBlock symbol={step.quoteToken} pools={step.pools} />
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
        {paths.map((path) => (
          <LineBezier key={path.end} d={path.end} />
        ))}
        <div ref={endRef} className="ml-[52px] flex items-center">
          <span className="mr-5 text-base leading-5 text-white">
            {route?.outputFormatted} {route?.quoteToken ?? ''}
          </span>
          <div className="h-9 w-9 rounded-full bg-red" />
        </div>
      </div>
    </div>
  )
}

interface BlockProps {
  symbol: string
  pools: Pool[]
}

function TokenBlock({ symbol, pools }: BlockProps) {
  return (
    <div className="rounded border border-border bg-bgLight p-2">
      <div className="mb-1 flex items-center">
        <div className="mr-1 h-4 w-4 rounded-[100%] bg-red" />
        <span className="whitespace-nowrap text-base leading-5 text-white">
          {symbol}
        </span>
      </div>
      {pools.map((pool) => (
        <div className="whitespace-nowrap text-xs leading-4 text-grayLight">
          {pool.dex} {numeral(pool.portion).format('0%')}
        </div>
      ))}
    </div>
  )
}

function LineBezier({ d }: { d: string }) {
  return (
    <svg className="absolute h-full w-full">
      <path className="line" d={d} markerEnd="url(#head)" />
    </svg>
  )
}

function LineStraight() {
  return (
    <div className="relative flex h-[20px] w-full items-center">
      <svg
        className="absolute h-[2px] w-[calc(100%-10px)]"
        viewBox="0 0 100% 100%"
      >
        <line
          strokeWidth={2}
          stroke="#5b1a23"
          strokeDasharray="4"
          x1="0"
          y1="0"
          x2="100%"
          y2="0"
          markerEnd="url(#head)"
        />
      </svg>
      <svg className="absolute right-0 h-[10px] w-[10px]" viewBox="-1 -1 8 8">
        <line
          strokeWidth={1}
          x1="0"
          y1="0"
          x2="2.5"
          y2="2.5"
          stroke="#5b1a23"
          strokeLinecap="round"
        />
        <line
          strokeWidth={1}
          x1="2.5"
          y1="2.5"
          x2="0"
          y2="5"
          stroke="#5b1a23"
          strokeLinecap="round"
        />
      </svg>
    </div>
  )
}

import { ReactComponent as ArrowBackIcon } from 'resources/images/icons/arrow-back.svg'
import { ReactComponent as WarningIcon } from 'resources/images/icons/exclam.svg'
import TextInput from 'components/dumb/TextInput'
import Button from 'components/dumb/Button'
import classNames from 'classnames'
import React from 'react'
import { toDecimal } from 'utils/numbers'
import { useStore } from 'effector-react'
import { $swap, setSlippage } from 'models/swap'

interface Props {
  open: boolean
  onBackClick(): void
}

export default function SmartSettings({ open, onBackClick }: Props) {
  const { slippage } = useStore($swap)

  const handleResetClick = () => {
    setSlippage('1')
  }

  const { msg, showError } = React.useMemo(() => {
    const inputDec = toDecimal(slippage)

    if (slippage === '') {
      return { msg: '', showError: false }
    }

    if (inputDec.lt(0.1)) {
      return { msg: 'Your transaction may fail', showError: true }
    } else if (inputDec.gt(20)) {
      return { msg: 'Enter a valid slippage percentage', showError: true }
    } else if (inputDec.gt(3) && inputDec.lte(20)) {
      return { msg: 'Your transaction may be frontrun', showError: true }
    }
    return {
      msg: '',
      showError: false,
    }
  }, [slippage])

  const handleInputChange = (v: string) => {
    setSlippage(v)
  }

  if (!open) {
    return null
  }

  return (
    <div className="text-sm leading-[1.3] text-white">
      <div className="flex items-center">
        <div
          id="smart-settings-arrow-back"
          className="cursor-pointer"
          onClick={onBackClick}
        >
          <ArrowBackIcon />
        </div>
        <span className="text-md m-auto leading-5">Transaction settings</span>
        <Button
          id="smart-settings-reset-btn"
          type="text"
          onClick={handleResetClick}
        >
          Reset
        </Button>
      </div>
      <div className="my-4 h-[1px] w-full bg-border" />
      <div className="mb-3 flex items-center">
        <span>Slippage tolerance</span>
        {/*<TooltipInfo label="text" placement="right" />*/}
      </div>
      <div className="flex">
        <div className="mr-4 flex space-x-2 rounded border border-border bg-bgDark p-1">
          <Preset
            id="slippage-option-0.1"
            onClick={(v) => setSlippage(v)}
            value="0.1"
          />
          <Preset
            id="slippage-option-0.5"
            onClick={(v) => setSlippage(v)}
            value="0.5"
          />
          <Preset
            id="slippage-option-1"
            onClick={(v) => setSlippage(v)}
            value="1"
          />
          <Preset
            id="slippage-option-3"
            onClick={(v) => setSlippage(v)}
            value="3"
          />
        </div>
        <div className="flex items-center">
          <div
            className={classNames(
              'relative mr-2 flex items-center rounded border border-border bg-bgDark p-2',
              {
                'border-yellow': showError,
              }
            )}
          >
            {showError && (
              <WarningIcon className="absolute left-2 mr-2 h-4 w-4" />
            )}
            <TextInput
              id="slippage-option-custom"
              value={slippage}
              onChange={handleInputChange}
              className="relative ml-4 w-[30px] flex-grow bg-transparent text-right text-sm leading-[1.3] text-white focus:outline-none"
            />
          </div>
          <span className="text-sm leading-[1.3] text-grayLight">%</span>
        </div>
      </div>
      <div className="relative mt-3 mb-2 h-4 text-sm leading-[1.3] text-yellow">
        {msg}
      </div>
    </div>
  )
}

interface PresetProps {
  id?: string
  value: string
  onClick(v: string): void
}

const Preset = ({ id, value, onClick }: PresetProps) => (
  <div
    onClick={() => onClick(value)}
    className="w-[42px] cursor-pointer rounded bg-bgDark p-1 text-center text-sm leading-[1.3] text-grayLight hover:bg-bgLight"
  >
    {value}%
  </div>
)

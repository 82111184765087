import TxToast from 'components/smart/SmartToasts'
import { createEffect, createEvent, createStore } from 'effector'
import { provider } from 'models/wallet'
import toast from 'react-hot-toast'
import invariant from 'tiny-invariant'

export type TxStatus = 'submitted' | 'pending' | 'failed' | 'confirmed'

export type Tx = {
  hash: string
  status: TxStatus
  msg?: string
}

export const $tx = createStore<Tx>({ hash: '', status: 'submitted' })
export const $transactions = createStore<Tx[]>([])

export const updateTxStatus = createEvent<{ hash: string; status: TxStatus }>()

export const emitTxToastFx = createEffect((tx: Tx) =>
  toast.custom((t) => <TxToast t={t} tx={tx} />, { duration: 60 * 60 * 4000 })
)

export const getTxStatus = createEffect(async (tx: Tx) => {
  invariant(provider, 'web3 provider not found')

  try {
    const hash = tx.hash

    const clear = setInterval(async () => {
      console.log('interval call: ', clear)
      if (!provider) return

      const receipt = await provider.getTransactionReceipt(hash)
      if (receipt) {
        const newStatus = receipt.status === 1 ? 'confirmed' : 'failed'
        updateTxStatus({ hash, status: newStatus })
        clearInterval(clear)
      }
    }, 2000)
  } catch (err) {
    console.error(err)
  }
})

export const appendTxFx = createEvent<Tx>()

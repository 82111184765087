import Button from 'components/dumb/Button'
import { Popover } from '@headlessui/react'
import { ReactComponent as SuccessIcon } from 'resources/images/icons/success.svg'
import { ReactComponent as HistoryIcon } from 'resources/images/icons/history.svg'
import { ReactComponent as CloseIcon } from 'resources/images/icons/close.svg'
import { ReactComponent as ClockIcon } from 'resources/images/icons/clock.svg'
import { ReactComponent as WarningIcon } from 'resources/images/icons/exclam.svg'
import { ReactComponent as LinkIcon } from 'resources/images/icons/link.svg'
import classNames from 'classnames'
import { useStore } from 'effector-react'
import { $connected } from 'models/wallet'
import { $transactions, Tx } from 'models/transactions'
import { getTxExplorerUrl } from 'utils'

export default function SmartHistory() {
  const connected = useStore($connected)
  const transactions = useStore($transactions)

  if (!connected) {
    return null
  }

  return (
    <div className="flex">
      <Popover className="relative">
        {({ close, open }) => (
          <>
            <Popover.Button className="outline-none">
              <Button
                className={classNames({
                  'border border-red/25 bg-red/25': open,
                })}
                type="secondary"
              >
                <div className="inline-flex items-center space-x-5">
                  <div>History</div>
                  <HistoryIcon />
                </div>
              </Button>
            </Popover.Button>

            <Popover.Panel className="absolute top-[50px] z-10 w-[400px] rounded border border-border bg-bgDark">
              <div className="mb-0 flex items-center justify-between px-5 py-4">
                <div className="whitespace-nowrap text-base leading-5 text-white">
                  Recent transactions
                </div>
                <CloseIcon
                  onClick={() => close()}
                  className="h-3 w-3 cursor-pointer text-grayLight"
                />
              </div>
              <div className="mx-2 mb-2 flex flex-col divide-y divide-border rounded border border-border bg-bgLight">
                {transactions.length === 0 ? (
                  <div className="py-4 px-3 text-sm leading-[1.3] text-grayLight">
                    Your transactions will appear here.
                  </div>
                ) : (
                  transactions.map((tx) => (
                    <Transaction key={tx.hash} tx={tx} />
                  ))
                )}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}

function Transaction({ tx }: { tx: Tx }) {
  return (
    <div className="flex items-center justify-between px-3 py-4">
      <div className="text-sm leading-[1.3] text-white">{tx.msg ?? ''}</div>
      <div className="flex items-center space-x-3">
        {tx.status === 'pending' || tx.status === 'submitted' ? (
          <ClockIcon className="h-4 w-4" />
        ) : null}
        {tx.status === 'confirmed' ? <SuccessIcon className="h-4 w-4" /> : null}
        {tx.status === 'failed' ? <WarningIcon className="h-4 w-4" /> : null}
        <a href={getTxExplorerUrl(tx.hash)} target="_blank" rel="noreferrer">
          <LinkIcon className="h-4 w-4 cursor-pointer" />
        </a>
      </div>
    </div>
  )
}

import { forwardRef } from 'react'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { noop } from 'utils'

interface Props {
  id?: string
  className?: string
  children?: ReactNode
  onClick?(): void
  disabled?: boolean
  type?: 'primary' | 'secondary' | 'tertiary' | 'text'
}

export default forwardRef(function Button(
  {
    id,
    children,
    className,
    onClick = noop,
    type = 'primary',
    disabled = false,
  }: Props,
  ref: any
) {
  const disabledCn =
    'disabled:bg-border disabled:pointer-events-none disabled:border-border'

  const base = classNames(
    className,
    {
      'py-2 px-5': type === 'primary' || type === 'secondary',
    },
    'text-white disabled:text-grayLight'
  )
  const primary =
    'text-lg border-red border font-medium bg-red/6 hover:bg-red/25 rounded-[2px] active:bg-red/25'

  const secondary =
    'bg-red/6 border-transparent border hover:border-red hover:border-opacity-25 rounded active:bg-red/25 text-base leading-[1.2]'

  const text =
    'text-sm leading-[1.3] text-red hover:underline disabled:bg-transparent'

  return (
    <button
      ref={ref}
      id={id}
      className={classNames(base, {
        [primary]: type === 'primary',
        [secondary]: type === 'secondary',
        [text]: type === 'text',
        [disabledCn]: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
})

import { sample, split } from 'effector'
import { persist } from 'effector-storage/local'
import produce from 'immer'
import {
  openTxFailModal,
  openTxRejectModal,
  openTxSuccessModal,
  openTxWaitModal,
} from 'models/modal'
import {
  $baseToken,
  $route,
  approveFx,
  fetchBalancesFx,
  swapFx,
} from 'models/swap'
import numeral from 'numeral'
import {
  $transactions,
  $tx,
  appendTxFx,
  emitTxToastFx,
  getTxStatus,
  Tx,
  updateTxStatus,
} from '.'

persist({ store: $transactions, key: 'transactions' })

const txFxs = [swapFx, approveFx]

sample({
  clock: txFxs,
  fn: () => true,
  target: openTxWaitModal,
})

sample({
  clock: txFxs,
  fn: (): Tx => ({ hash: '', status: 'pending' }),
  target: $tx,
})

txFxs.forEach((fx) => {
  sample({
    clock: fx.doneData,
    fn: (hash): Tx => ({ hash, status: 'submitted' }),
    target: [$tx, fetchBalancesFx, openTxSuccessModal],
  })

  sample({
    clock: fx.failData,
    source: $tx,
    fn: (tx): Tx => ({ ...tx, status: 'failed' }),
    target: [$tx],
  })

  split({
    source: fx.failData,
    match: {
      rejected: (err: any) => err.code && err.code === 4001,
      failed: () => true,
    },
    cases: {
      rejected: openTxRejectModal,
      failed: openTxFailModal,
    },
  })
})

sample({
  clock: appendTxFx,
  source: $transactions,
  fn: (transactions, tx) =>
    produce(transactions, (draft) => {
      draft.unshift(tx)
      draft.splice(5)
    }),
  target: $transactions,
})

sample({
  clock: updateTxStatus,
  source: $transactions,
  fn: (transactions, payload) =>
    produce(transactions, (draft) => {
      const index = draft.findIndex((t) => t.hash === payload.hash)
      if (index !== -1) {
        draft[index].status = payload.status
      }
    }),
  target: $transactions,
})

sample({
  clock: swapFx.doneData,
  source: $route,
  fn: (route, hash): Tx => ({
    hash,
    msg: `Swap ${numeral(route?.raw?.volume).format('0,.[000000]')} ${
      route?.baseToken
    } for ${numeral(route?.raw?.outputAmount).format('0,.[000000]')} ${
      route?.quoteToken
    }`,
    status: 'submitted',
  }),
  target: [emitTxToastFx, appendTxFx, getTxStatus],
})

sample({
  clock: approveFx.doneData,
  source: $baseToken,
  fn: (baseToken, hash): Tx => ({
    hash,
    msg: `Approve ${baseToken.symbol} for spending`,
    status: 'submitted',
  }),
  target: [emitTxToastFx, appendTxFx, getTxStatus],
})

swapFx.fail.watch((err) => {
  console.log('swap err:')
  console.log(err)
})

import ModalTitle from 'components/dumb/ModalTitle'
import { closeModal } from 'models/modal'
import { ReactComponent as SpinnerIcon } from 'resources/images/icons/spinner.svg'
import Button from './Button'

export default function TxWaitModal() {
  return (
    <div className="flex flex-grow flex-col items-center">
      <SpinnerIcon className="mb-5 h-12 w-12 animate-spin-slow" />
      <ModalTitle>Pending confirmation</ModalTitle>
      <p className="mb-10 text-center text-lg leading-[1.3] text-white">
        Please, sign transaction in your wallet.
      </p>
      <Button className="w-full" onClick={() => closeModal()}>
        Close
      </Button>
    </div>
  )
}

import {
  createStore,
  createEffect,
  attach,
  createEvent,
  combine,
} from 'effector'
import fetcher from 'utils/fetcher'
import { TokenType } from 'models/types'
import { convertHexToNumber, toDecimal } from 'utils/numbers'
import { CONTRACT_ABI, TOKEN_ABI } from './abi'
import { $address, provider } from 'models/wallet'
import { ethers } from 'ethers'
import invariant from 'tiny-invariant'
import { desiredChain, tokensUrl } from 'utils'
import { RouteProxy } from 'models/routeProxy'
import { GetRouteQueryVariables, RouteResponse } from 'gql'
import { buildSwapArgs, estimateSwapFee, SwapFee } from './estimateSwapFee'

interface SwapInfo {
  baseTokenAddress: string | null
  quoteTokenAddress: string | null
  input: string
  inputUSD: string
  output: string
  outputUSD: string
  slippage: string
}

const emptySwapInfo = {
  baseTokenAddress: null,
  quoteTokenAddress: null,
  input: '',
  inputUSD: '',
  output: '',
  outputUSD: '',
  slippage: '1',
}

const emptyToken = {
  address: '',
  decimals: 0,
  logoURI: '',
  name: '',
  symbol: '',
  balance: '0',
  allowance: '0',
  isCustom: false,
}

export const $swap = createStore<SwapInfo>(emptySwapInfo)
export const $estimatedFee = createStore<null | SwapFee>(null)
export const $estimateFeeError = createStore<null | string>(null)
export const $estimateFeeLoading = createStore(false)

export const $routeRaw = createStore<
  Omit<RouteResponse, '__typename'> | undefined | null
>(null)
export const $route = createStore<RouteProxy | null>(null)

export const $tokens = createStore<TokenType[]>([])
export const $tokensWithNative = $tokens
export const $userImportedTokens = createStore<TokenType[]>([])
export const $unimportedToken = createStore<TokenType | null>(null)

export const $loading = createStore<boolean>(false)
export const $balancesReady = createStore<boolean>(false)
export const $routeError = createStore<string>('')

export const fetchTokens = createEvent()
export const swapTokens = createEvent()
export const importToken = createEvent()
export const removeToken = createEvent<TokenType>()

export const setBaseToken = createEvent<string>()
export const setQuoteToken = createEvent<string>()
export const setDebouncedInput = createEvent<string>()
export const setSlippage = createEvent<string>()

export const setInput = createEvent<string>()
export const $input = $swap.map((swap) => swap.input)

export const $baseToken = combine(
  $swap,
  $tokensWithNative,
  ({ baseTokenAddress }, tokens) =>
    tokens.find((t) => t.address === baseTokenAddress) ?? emptyToken
)
export const $quoteToken = combine(
  $swap,
  $tokensWithNative,
  ({ quoteTokenAddress }, tokens) =>
    tokens.find((t) => t.address === quoteTokenAddress) ?? emptyToken
)

export const fetchTokensFx = createEffect<void, TokenType[], Error>(
  async () => {
    const res = await fetcher.get(tokensUrl)

    const mapped = res.map((t: any) => ({
      ...t,
      balance: '0',
      allowance: '0',
      isCustom: false,
    })) as unknown as TokenType[]
    return mapped
  }
)

export const fetchTokenByAddress = attach({
  source: $address,
  async effect(address, tokenAddress) {
    const contractAddr = process.env.REACT_APP_CONTRACT_ADDRESS ?? ''

    invariant(tokenAddress, 'tokenAddress not found')
    invariant(address, 'address not found')
    invariant(provider, 'web3 provider not found')
    invariant(contractAddr, 'contractAddr not found')

    try {
      const erc20 = new ethers.Contract(tokenAddress, TOKEN_ABI, provider)
      const balanceHex = await erc20.balanceOf(address)
      const allowanceHex = await erc20.allowance(address, contractAddr)
      const symbol = await erc20.symbol()
      const name = await erc20.name()
      const decimals = await erc20.decimals()

      const balance = convertHexToNumber(balanceHex, decimals)
      const allowance = convertHexToNumber(allowanceHex, decimals)
      const tokenData: TokenType = {
        address: tokenAddress,
        name,
        logoURI: '',
        symbol,
        decimals,
        balance,
        allowance,
        isCustom: true,
      }

      return tokenData
    } catch (err) {
      console.error(err)
      return null
    }
  },
})

const fetchRoutesEffect = createEffect(
  async (params: GetRouteQueryVariables) => {
    return await fetcher.gql(params)
  }
)

export const fetchRoutesFx = attach({
  source: [$swap, $input],
  mapParams(params: Partial<GetRouteQueryVariables>, [swap, input]) {
    const wadaAddress =
      process.env.REACT_APP_WADA_ADDRESS ||
      '0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9'

    let bta = params.baseToken ?? swap.baseTokenAddress ?? ''
    let qta = params.quoteToken ?? swap.quoteTokenAddress ?? ''

    if (swap.baseTokenAddress === '0' && wadaAddress) {
      bta = wadaAddress
    }

    if (swap.quoteTokenAddress === '0' && wadaAddress) {
      qta = wadaAddress
    }
    return {
      baseToken: bta,
      quoteToken: qta,
      volume: params.volume ?? input,
      slippage: params.slippage ?? toDecimal(swap.slippage).div(100).toNumber(),
    }
  },
  effect: fetchRoutesEffect,
})

export const fetchADABalanceFx = attach({
  source: $address,
  async effect(address) {
    invariant(address, 'address not found')
    invariant(provider, 'web3 provider not found')

    const balance = await provider.getBalance(address)
    return toDecimal(balance._hex).div(1e18).toString()
  },
})

export const fetchBalancesFx = attach({
  source: [$address, $tokens],
  async effect([address, tokens]) {
    const contractAddr = process.env.REACT_APP_CONTRACT_ADDRESS ?? ''

    invariant(address, 'address not found')
    invariant(provider, 'web3 provider not found')
    invariant(contractAddr, 'contractAddr not found')

    let proms = []
    let allowanceProms = []
    let results = []

    for (const tkn of tokens) {
      const erc20 = new ethers.Contract(tkn.address, TOKEN_ABI, provider)
      proms.push(erc20.balanceOf(address))

      allowanceProms.push(erc20.allowance(address, contractAddr))
    }

    const balancesResults = await Promise.allSettled(proms)
    const allowanceResults = await Promise.allSettled(allowanceProms)

    const milkADABalance = await provider.getBalance(address)

    for (let index = 0; index < balancesResults.length; index++) {
      if (tokens[index].address === '0') {
        results.push({
          name: desiredChain.nativeCurrency.name,
          symbol: desiredChain.nativeCurrency.symbol,
          balance: toDecimal(milkADABalance._hex).div(1e18).toString(),
          allowance: '99999999999999',
        })
        continue
      }

      let bal = '0'
      let al = '0'
      if (
        balancesResults[index].status === 'fulfilled' &&
        allowanceResults[index].status === 'fulfilled'
      ) {
        const balRes = balancesResults[index] as PromiseFulfilledResult<string>
        const alRes = allowanceResults[index] as PromiseFulfilledResult<string>
        bal = convertHexToNumber(balRes.value, tokens[index].decimals)
        al = convertHexToNumber(alRes.value, tokens[index].decimals)
      }
      results.push({
        name: tokens[index].name,
        symbol: tokens[index].symbol,
        balance: bal,
        allowance: al,
      })
    }

    return results
  },
})

export const approveFx = attach({
  source: [$address, $swap],
  async effect([address, swap]) {
    const contractAddr = process.env.REACT_APP_CONTRACT_ADDRESS ?? ''

    invariant(address, 'address not found')
    invariant(provider, 'web3 provider not found')
    invariant(swap.baseTokenAddress, 'baseTokenAddress not found')
    invariant(contractAddr, 'contractAddr not found')

    await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const signer = provider.getSigner()
    const tokenContract = new ethers.Contract(
      swap.baseTokenAddress,
      TOKEN_ABI,
      signer
    )

    const res = await tokenContract.approve(
      contractAddr,
      '1000000000000000000000000'
    )
    return `${res?.hash ?? ''}`
  },
})

const estimateGasEffect = createEffect(
  async ({
    baseToken,
    quoteToken,
    routeProxy,
  }: {
    baseToken: TokenType
    quoteToken: TokenType
    routeProxy?: RouteProxy
  }) => {
    const wadaAddress = process.env.REACT_APP_WADA_ADDRESS ?? ''
    const contractAddr = process.env.REACT_APP_CONTRACT_ADDRESS ?? ''

    invariant(provider, 'web3 provider not found')
    invariant(contractAddr, 'contractAddr not found')
    invariant(baseToken, 'baseToken not found')
    invariant(quoteToken, 'quoteToken not found')

    await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const route = routeProxy?.raw
      ? routeProxy.raw
      : await fetcher.gql({
          baseToken:
            baseToken.address === '0' ? wadaAddress : baseToken.address,
          quoteToken:
            quoteToken.address === '0' ? wadaAddress : quoteToken.address,
          volume: '0.001',
        })

    const args = buildSwapArgs(baseToken, quoteToken, route)

    const fee = await estimateSwapFee(args)

    return { fee, args }
  }
)

export const estimateGasFx = attach({
  source: [$baseToken, $quoteToken],
  mapParams({ route }: { route?: RouteProxy }, [baseToken, quoteToken]) {
    return { baseToken, quoteToken, routeProxy: route }
  },
  effect: estimateGasEffect,
})

export const swapFx = attach({
  source: [$route, $baseToken, $quoteToken],
  effect: async ([route, baseToken, quoteToken]) => {
    const contractAddr = process.env.REACT_APP_CONTRACT_ADDRESS ?? ''

    invariant(provider, 'web3 provider not found')
    invariant(route?.raw, 'route.raw is not defined')

    const args = buildSwapArgs(baseToken, quoteToken, route.raw)

    const { fee } = await estimateGasFx({ route })

    const signer = provider.getSigner()
    const contract = new ethers.Contract(contractAddr, CONTRACT_ABI, signer)

    const res = await contract.swap(args, {
      gasLimit: fee.gasLimit,
      gasPrice: fee.gasPrice,
      value: baseToken.address === '0' ? args.volume : undefined,
    })
    return `${res?.hash ?? ''}`
  },
})

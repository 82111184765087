import throttle from 'lodash/throttle'
import { useLayoutEffect, useState } from 'react'

export default function useResize() {
  const [w, setWidth] = useState<number>(0)
  const [h, setHeight] = useState<number>(0)

  useLayoutEffect(() => {
    const handleResize = throttle(() => {
      const width = window.innerWidth
      const height = window.innerHeight
      setWidth(width)
      setHeight(height)
    }, 100)

    handleResize()

    window.addEventListener('resize', handleResize)
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { width: w, height: h }
}

import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-down.svg'
import { ReactComponent as SearchIcon } from 'resources/images/icons/search.svg'
import { Combobox } from '@headlessui/react'
import TextInput from 'components/dumb/TextInput'
import classNames from 'classnames'
import { TokenType } from 'models/types'
import { useEffect, useState } from 'react'
import { toDecimal } from 'utils/numbers'
import numeral from 'numeral'
import { noop } from 'utils'
import Button from './Button'
import { ethers } from 'ethers'
import { fetchTokenByAddress, importToken, removeToken } from 'models/swap'

interface BaseProps {
  id?: string
  symbol: string
  name: string
  value: string
  type: 'bottom' | 'top'
  tokens: TokenType[]
  onSelect(item: TokenType): void
  unimportedToken: TokenType | null
  selectedItem?: TokenType | null
  label?: React.ReactNode
  currency?: string | null
  disabled?: boolean
  valueUSD?: string
  decimals?: number
  placeholder?: string
}

interface TopProps extends BaseProps {
  onUserInput(value: string): void
  showMaxButton: boolean
  onMax(): void
  type: 'top'
}

interface BottomProps extends BaseProps {
  type: 'bottom'
  children: React.ReactNode
}

export default function CurrencyInputPanel(props: TopProps | BottomProps) {
  const {
    id,
    symbol,
    name,
    value,
    onSelect,
    unimportedToken,
    tokens: items,
    selectedItem,
    disabled = false,
    valueUSD = '',
    decimals = 0,
    placeholder = '',
  } = props

  const [query, setQuery] = useState('')

  const isAddress = ethers.utils.isAddress(query)

  const showUnimportedToken = isAddress && unimportedToken ? true : false

  useEffect(() => {
    if (isAddress) {
      fetchTokenByAddress(query)
    }
  }, [query, isAddress])

  const filtered =
    query === ''
      ? items
      : items.filter((item) => {
          return (
            item.symbol.toLowerCase().includes(query.toLowerCase()) ||
            item.address.toLowerCase() === query.toLowerCase()
          )
        })

  const _isTop = isTop(props)
  const _isBottom = isBottom(props)
  const valueUSDDec = toDecimal(valueUSD)

  const handleSelect = (item: TokenType) => {
    setQuery('')
    onSelect(item)
  }

  const onCloseOptions = () => {
    setQuery('')
  }

  const onImportTokenClick = () => {
    importToken()
    setQuery('')
  }

  return (
    <div className="relative">
      <Combobox value={selectedItem} onChange={handleSelect}>
        {({ open }) => (
          <>
            <div
              className={classNames('flex flex-col', {
                'bg-bgDark': _isBottom,
              })}
            >
              <div
                className={classNames(`flex rounded`, {
                  'border border-border': _isTop,
                  'rounded-br-none rounded-bl-none border-b-0': open,
                })}
              >
                <Combobox.Button>
                  <div className="flex min-w-[10rem] max-w-[10rem] cursor-pointer items-center justify-between px-5 py-3 pr-3">
                    <div className="truncate text-left text-grayLight">
                      <p className="select-none whitespace-nowrap text-[22px] leading-7 text-red">
                        {symbol}
                      </p>
                      <span className="select-none truncate whitespace-nowrap text-sm leading-[1.3]">
                        {name}
                      </span>
                    </div>
                    <ChevronIcon
                      id={`${id}-chevron-icon`}
                      className={classNames('fill-current text-white', {
                        'rotate-180': open,
                      })}
                    />
                  </div>
                </Combobox.Button>
                <div className="flex grow rounded-r bg-bgDark py-3">
                  <div
                    className={classNames('flex flex-col', {
                      'mr-3 mt-2 mb-2 rounded bg-bgDark px-3 py-2': _isBottom,
                      'px-5': _isTop,
                    })}
                  >
                    <TextInput
                      id={`${id}-input`}
                      disabled={disabled}
                      value={value}
                      placeholder={placeholder}
                      className="relative top-1 ml-2 w-[calc(100%-0.5rem)] flex-grow bg-transparent text-right text-[22px] leading-7 text-white focus:outline-none"
                      onChange={_isTop ? props.onUserInput : noop}
                      decimals={decimals}
                    />
                    <div className="flex items-center justify-between">
                      <Button
                        id={`${id}-max-btn`}
                        type="text"
                        className={classNames({
                          'opacity-100': _isTop && props.showMaxButton,
                          'pointer-events-none opacity-0':
                            (_isTop && !props.showMaxButton) || !_isTop,
                        })}
                        onClick={_isTop ? props.onMax : noop}
                      >
                        MAX
                      </Button>
                      {valueUSDDec.gt(0) ? (
                        <p className="text-sm leading-[1.3] text-grayLight">
                          ≈${numeral(valueUSD).format('0,[.][0000]')}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {open ? (
                <div className="relative">
                  <div
                    className={classNames('absolute z-40 w-full', {
                      'border border-border bg-bgLight': _isTop,
                      'bg-bgDark': _isBottom,
                    })}
                  >
                    <div className="flex w-full items-center p-3">
                      <SearchIcon className="mr-2" />
                      <Combobox.Input
                        id={`${id}-search-input`}
                        placeholder="Search"
                        className={`${id}-search-input w-full bg-transparent text-sm leading-[1.3] text-white outline-none`}
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>
                    <Options
                      id={id}
                      _isTop={_isTop}
                      _isBottom={_isBottom}
                      filtered={filtered}
                      showUnimportedToken={showUnimportedToken}
                      unimportedToken={unimportedToken}
                      query={query}
                      onCloseOptions={onCloseOptions}
                      onImportTokenClick={onImportTokenClick}
                    />
                  </div>
                </div>
              ) : null}
              {_isBottom ? (
                <div className="px-5 pb-5">{props.children}</div>
              ) : null}
            </div>
          </>
        )}
      </Combobox>
    </div>
  )
}

interface OptionsProps {
  id?: string
  _isTop: boolean
  _isBottom: boolean
  filtered: TokenType[]
  showUnimportedToken: boolean
  unimportedToken: TokenType | null
  query: string
  onCloseOptions(): void
  onImportTokenClick(): void
}

function Options({
  id,
  _isTop,
  _isBottom,
  filtered,
  showUnimportedToken,
  unimportedToken,
  query,
  onCloseOptions,
  onImportTokenClick,
}: OptionsProps) {
  useEffect(() => {
    return () => {
      onCloseOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Combobox.Options className={`${id}-options`} unmount>
      <div
        className={classNames('overflow-x-hidden overflow-y-scroll', {
          'h-[21.25rem]': _isTop,
          'h-[17.25rem]': _isBottom,
        })}
      >
        {filtered.map((item) => (
          <ComboboxOption
            id={`${id}-${item.symbol}-option`}
            key={item.symbol}
            token={item}
          >
            {item.isCustom ? (
              <Button
                id={`${id}-remove-custom-token-btn`}
                className="flex grow justify-end"
                type="text"
                onClick={() => removeToken(item)}
              >
                Remove
              </Button>
            ) : null}
          </ComboboxOption>
        ))}
        {filtered.length === 0 && query.length > 0 ? (
          <div
            className={classNames(
              'bg-bgLight p-3 text-sm uppercase leading-7 text-grayDark',
              {
                'border-t border-border': _isTop,
              },
              {
                'bg-bgDark': _isBottom,
              }
            )}
          >
            {showUnimportedToken
              ? 'Expanded results from inactive Token Lists'
              : 'No results found.'}
          </div>
        ) : null}
        {showUnimportedToken && unimportedToken ? (
          <ComboboxOption
            id={`${id}-${unimportedToken.symbol}-option`}
            token={unimportedToken}
          >
            <Button
              id={`${id}-import-token-btn`}
              className="flex grow justify-end"
              type="text"
              onClick={onImportTokenClick}
            >
              Import
            </Button>
          </ComboboxOption>
        ) : null}
      </div>
    </Combobox.Options>
  )
}

function ComboboxOption({
  id,
  token,
  children = null,
}: {
  id?: string
  token: TokenType
  children?: React.ReactNode
}) {
  return (
    <Combobox.Option
      id={`${id}-option`}
      value={token}
      className={({ selected }) =>
        classNames(
          id,
          'flex cursor-pointer items-center space-x-3 p-3 hover:bg-border',
          {
            'bg-red/6': selected,
          }
        )
      }
    >
      <div className="relative shrink-0 overflow-hidden rounded border border-red/25 p-[0.20rem]">
        <img className="h-[16px] w-[16px]" src={token.logoURI} alt="" />
      </div>
      <span className="text-[1.1rem] leading-7 text-white">{token.symbol}</span>
      <span className="text-sm uppercase leading-[1.3] text-grayDark">
        {token.name}
      </span>
      {toDecimal(token.balance).gt(0) ? (
        <span className="flex grow justify-end text-sm leading-[1.3] text-white">
          {formatBalance(token.balance)}
        </span>
      ) : null}

      {children}
    </Combobox.Option>
  )
}

function isTop(props: TopProps | BottomProps): props is TopProps {
  return props.type === 'top'
}
function isBottom(props: TopProps | BottomProps): props is BottomProps {
  return props.type === 'bottom'
}
function formatBalance(balance: string) {
  const balanceDec = toDecimal(balance)
  return balanceDec.lt(1e-6) ? '0.00' : numeral(balance).format('0,0.00')
}

import React, { useEffect } from 'react'

export default function useOutsideClick(
  ref: React.RefObject<HTMLElement>,
  callback?: (e: MouseEvent) => void
) {
  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        callback && callback(event)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick, {
      passive: true,
    })

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ref, callback])
}

import Button from 'components/dumb/Button'
import CurrencyInputPanel from 'components/dumb/CurrencyInputPanel'
import DetailsAndFeesBlock from 'components/dumb/DetailsAndFeesBlock'
import { Tooltip } from 'components/dumb/Tooltip'
import { useStore } from 'effector-react'
import debounce from 'lodash/debounce'
import {
  $balancesReady,
  $baseToken,
  $estimatedFee,
  $estimateFeeError,
  $estimateFeeLoading,
  $loading,
  $quoteToken,
  $route,
  $routeError,
  $swap,
  $tokensWithNative,
  $unimportedToken,
  approveFx,
  fetchRoutesFx,
  setBaseToken,
  setDebouncedInput,
  setInput,
  setQuoteToken,
  swapTokens,
} from 'models/swap'
import { TokenType } from 'models/types'
import {
  $connected,
  $networkIsOk,
  connectWallet,
  switchNetworkFx,
} from 'models/wallet'
import { useMemo, useState } from 'react'
import { ReactComponent as ArrowIcon } from 'resources/images/icons/direction.svg'
import { ReactComponent as GearIcon } from 'resources/images/icons/gear.svg'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-thin.svg'
import SmartSettings from './SmartSettings'
import { useButtonValidation } from './useButtonValidation'
import { openConfirmSwapModal, openRouteDetailsModal } from 'models/modal'
import classNames from 'classnames'
import { toDecimal } from 'utils/numbers'

export default function SmartSwap() {
  const [settingsOpen, setSettingsOpen] = useState(false)

  const tokens = useStore($tokensWithNative)

  const connected = useStore($connected)
  const networkIsOk = useStore($networkIsOk)

  const loading = useStore($loading)
  const balancesReady = useStore($balancesReady)
  const unimportedToken = useStore($unimportedToken)

  const estimatedFee = useStore($estimatedFee)
  const estimateFeeError = useStore($estimateFeeError)
  const estimateFeeLoading = useStore($estimateFeeLoading)

  const { input, output, outputUSD, inputUSD } = useStore($swap)

  const baseToken = useStore($baseToken)
  const quoteToken = useStore($quoteToken)

  const route = useStore($route)
  const routeError = useStore($routeError)

  const approved = toDecimal(baseToken?.allowance).gt(0)

  const { buttonText, buttonDisabled } = useButtonValidation({
    input,
    connected,
    networkIsOk,
    loading: loading || estimateFeeLoading,
    fee: estimatedFee,
    feeError: estimateFeeError,
    loadingBalances: balancesReady === false,
    baseTokenBalance: baseToken?.balance,
    baseTokenSymbol: baseToken?.symbol,
    approved,
    routeError:
      routeError.includes('cannot find path') ||
      routeError.includes('not found'),
    isPriceImpactTooHigh: Boolean(route?.isPriceImpactTooHigh),
  })

  const debouncedHandler = useMemo(
    () =>
      debounce((v: string) => {
        setDebouncedInput(v)
      }, 500),
    []
  )

  const handleTypeInput = (value: string) => {
    setInput(value)
    debouncedHandler(input)
  }

  const handleInputTokenSelect = (item: TokenType) => {
    setBaseToken(item.address)
  }

  const handleOutputTokenSelect = (item: TokenType) => {
    setQuoteToken(item.address)
  }

  const handleSwapCurrencies = () => {
    swapTokens()
  }

  const handleMaxClick = () => {
    const found = tokens.find((t) => t.address === baseToken?.address)

    if (found) {
      const balance =
        found.address === '0'
          ? toDecimal(found.balance)
              .sub(estimatedFee?.fee || toDecimal(0))
              .toString()
          : found.balance
      setInput(balance)
      fetchRoutesFx({})
    }
  }

  const handleButtonClick = () => {
    if (!connected) {
      connectWallet()
    } else if (!networkIsOk) {
      switchNetworkFx()
    } else if (!approved) {
      approveFx()
    } else {
      openConfirmSwapModal()
    }
  }

  const routeInvalid = !route || !route.routes || route.routes.length === 0
  const routingDetailsButtonDisabled = routeInvalid || toDecimal(input).eq(0)
  const submitButtonDisabled = routeInvalid || buttonDisabled

  return (
    <div className="relative m-auto my-[108px] w-[410px]">
      <div className="shadow-blur absolute left-1/2 top-1/2 z-[1] h-[70%] w-[70%] -translate-x-1/2 -translate-y-1/2 rounded-[3rem] bg-red/20" />
      <div className="relative z-[2] rounded-md border border-border bg-bgLight px-6 py-8 pb-6">
        {settingsOpen ? (
          <SmartSettings
            open={settingsOpen}
            onBackClick={() => setSettingsOpen(false)}
          />
        ) : (
          <>
            <div className="mb-4 flex items-center justify-between">
              <span className="text-sm text-white">SWAP</span>
              <Tooltip label="Settings" placement="top">
                <div
                  id="smart-settings-gear-icon"
                  onClick={() => setSettingsOpen(true)}
                  className="relative h-[2.375rem] w-[2.375rem] cursor-pointer rounded hover:bg-bgDark/50"
                >
                  <GearIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </div>
              </Tooltip>
            </div>
            <div className="relative">
              <CurrencyInputPanel
                id="swap-tokenA"
                type="top"
                symbol={baseToken?.symbol ?? ''}
                unimportedToken={unimportedToken}
                name={baseToken?.name ?? ''}
                decimals={baseToken?.decimals ?? 0}
                value={input}
                valueUSD={inputUSD}
                onUserInput={handleTypeInput}
                onMax={handleMaxClick}
                showMaxButton={connected && networkIsOk && !estimateFeeLoading}
                tokens={tokens}
                onSelect={handleInputTokenSelect}
                selectedItem={baseToken}
                placeholder="0.00"
              />
            </div>
            <div className="relative mt-8">
              <div
                onClick={handleSwapCurrencies}
                id="swap-currencies-button"
                className="absolute -top-4 left-5 z-[3] box-border h-[1.87rem] w-[1.87rem] cursor-pointer rounded-sm border border-border bg-bgLight hover:bg-bgDark"
              >
                <div className="absolute top-1/2 left-1/2 h-3/5 w-3/5 -translate-x-1/2 -translate-y-1/2 transform">
                  <ArrowIcon className="text-primary-text h-full w-full fill-current" />
                </div>
              </div>
            </div>
            <CurrencyInputPanel
              id="swap-tokenB"
              type="bottom"
              unimportedToken={unimportedToken}
              symbol={quoteToken?.symbol || ''}
              decimals={quoteToken?.decimals ?? 0}
              name={quoteToken?.name || ''}
              value={output}
              valueUSD={outputUSD}
              tokens={tokens}
              onSelect={handleOutputTokenSelect}
              selectedItem={quoteToken}
              disabled
            >
              <Button
                id="main-btn"
                onClick={handleButtonClick}
                disabled={submitButtonDisabled}
                className={classNames(
                  'w-full',
                  Boolean(route?.isPriceImpactTooHigh) && 'bg-red'
                )}
              >
                {buttonText}
              </Button>
            </CurrencyInputPanel>
            <div className="relative w-full">
              <DetailsAndFeesBlock estimatedFee={estimatedFee} />
              <div className="absolute top-0 right-0 flex items-center">
                <Button
                  id="routing-details-btn"
                  type="text"
                  className="mr-2 whitespace-nowrap"
                  onClick={() => openRouteDetailsModal()}
                  disabled={routingDetailsButtonDisabled}
                >
                  Routing details
                </Button>
                <ChevronIcon
                  className={classNames('rotate-90 fill-current text-red', {
                    'text-grayLight': routingDetailsButtonDisabled,
                  })}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

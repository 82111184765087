import { sample, combine, split } from 'effector'
import { persist } from 'effector-storage/local'
import { desiredChainId } from 'utils'
import {
  $address,
  $connectionState,
  $networkIsOk,
  addNetworkFx,
  logout,
  metamaskAuthFx,
  provider,
  setAddress,
  setNetworkIsOk,
  setConnectionState,
  switchNetworkFx,
  ConnectionState,
  connectWallet,
  $connected,
} from '.'

persist({ store: $connectionState, key: 'connectionState' })
persist({ store: $networkIsOk, key: 'networkIsOk' })
persist({ store: $address, key: 'address' })

sample({
  clock: switchNetworkFx.fail,
  target: addNetworkFx,
})

sample({
  clock: addNetworkFx.done,
  target: switchNetworkFx,
})

provider?.on('network', (currentChain) => {
  const ok = currentChain?.chainId === Number(desiredChainId)
  setNetworkIsOk(ok)
})

window.ethereum?.on('accountsChanged', (accounts) => {
  if (Array.isArray(accounts) && accounts.length > 0) {
    setAddress(accounts[0])
  }
})

sample({
  clock: metamaskAuthFx.doneData,
  fn: (auth) => auth.address,
  target: setAddress,
})

sample({
  clock: metamaskAuthFx.doneData,
  fn: (): ConnectionState => 'connected',
  target: setConnectionState,
})

sample({
  clock: metamaskAuthFx.doneData,
  source: $networkIsOk,
  fn: (networkIsOk) => !networkIsOk,
  target: switchNetworkFx,
})

sample({
  clock: metamaskAuthFx.fail,
  fn: (): ConnectionState => 'error',
  target: setConnectionState,
})

split({
  clock: connectWallet,
  source: combine({ networkIsOk: $networkIsOk, connected: $connected }),
  match: {
    auth: ({ connected }) => {
      console.log(connected)
      return !connected
    },
    switchNetwork: ({ networkIsOk, connected }) => !networkIsOk && connected,
    logout: ({ connected, networkIsOk }) => connected && networkIsOk,
  },
  cases: {
    auth: metamaskAuthFx,
    switchNetwork: switchNetworkFx,
    logout: logout,
  },
})

$connectionState.reset(logout)
$address.reset(logout)

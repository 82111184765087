import { cloneElement, useMemo, useState, useRef } from 'react'
import { ReactComponent as InfoIcon } from 'resources/images/icons/info.svg'
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  arrow,
} from '@floating-ui/react-dom-interactions'
import { mergeRefs } from 'react-merge-refs'

interface Props {
  label: string
  hide?: boolean
  placement?: Placement
}

interface WithChildrenProps extends Props {
  children: JSX.Element
}

type TooltipProps = Props | WithChildrenProps

export const Tooltip = (props: TooltipProps) => {
  const [open, setOpen] = useState(false)

  const { hide = false, label, placement = 'bottom' } = props

  const hasChildren = isWithChildren(props)
  const children = hasChildren ? props.children : null

  const arrowRef = useRef(null)

  const { x, y, reference, floating, strategy, context, middlewareData } =
    useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(12),
        flip(),
        shift({ padding: 8 }),
        arrow({ element: arrowRef }),
      ],
      whileElementsMounted: autoUpdate,
    })

  const { arrow: { x: arrowX, y: arrowY } = {} } = middlewareData

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ])

  const ref = useMemo(
    //@ts-ignore
    () => (hasChildren ? mergeRefs([reference, (children as any).ref]) : null),
    [reference, children, hasChildren]
  )

  const staticSide =
    {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]] ?? ''

  return (
    <>
      {hasChildren ? (
        cloneElement(
          props.children,
          getReferenceProps({ ref, ...props.children.props })
        )
      ) : (
        <div
          ref={reference}
          className="ml-1 cursor-pointer"
          {...getReferenceProps()}
        >
          <InfoIcon />
        </div>
      )}
      {open && !hide && (
        <div
          {...getFloatingProps({
            ref: floating,
            className:
              'pointer-events-none bg-[#222] z-50 shadow-tooltipFilter rounded-md',
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            },
          })}
        >
          <div className="relative z-50 px-3 py-2 text-sm text-white">
            {label}
          </div>
          <div
            ref={arrowRef}
            className=" absolute z-40 h-3 w-3 rotate-45 bg-[#222]"
            id="arrow"
            style={{
              left: x != null ? `${arrowX}px` : '',
              top: y != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
              [staticSide]: '-0.25rem',
            }}
          />
        </div>
      )}
    </>
  )
}

function isWithChildren(
  props: Props | WithChildrenProps
): props is WithChildrenProps {
  return 'children' in props
}

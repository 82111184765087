import TxWaitModal from 'components/dumb/TxWaitModal'
import TxSuccessModal from 'components/dumb/TxSuccessModal'
import TxFailureModal from 'components/dumb/TxFailureModal'
import TxRejectedModal from 'components/dumb/TxRejectedModal'
import SmartConfirmSwapModal from 'components/smart/SmartConfirmSwapModal'
import { useStore } from 'effector-react'
import { $modalState, ModalState } from 'models/modal'
import SmartModalContainer from './SmartModalContainer'
import SmartRouteDetailsModal from '../SmartRouteDetailsModal'
import { $tx } from 'models/transactions'

export default function SmartModal() {
  const modalState = useStore($modalState)
  const tx = useStore($tx)

  let modal = null
  let className = ''

  switch (modalState) {
    case ModalState.TxWait:
      modal = <TxWaitModal />
      break
    case ModalState.TxSuccess:
      modal = <TxSuccessModal tx={tx} />
      break
    case ModalState.TxFailure:
      modal = <TxFailureModal />
      break
    case ModalState.TxRejected:
      modal = <TxRejectedModal />
      break
    case ModalState.ConfirmSwap:
      modal = <SmartConfirmSwapModal />
      break
    case ModalState.RouteDetails:
      modal = <SmartRouteDetailsModal />
      className = '!w-[964px]'
      break
  }

  return (
    <SmartModalContainer className={className}>{modal}</SmartModalContainer>
  )
}

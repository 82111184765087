import { NodeXY } from './types'

interface Options {
  from: NodeXY
  to: NodeXY
}

export function connectionPath({ from, to }: Options) {
  const { x: x1, y: y1 } = from
  const { x: x2, y: y2 } = to

  if (y1 === y2) {
    return `M ${x1} ${y1} L ${x2} ${y2}`
  }

  const size = 10

  const q1 = {
    x1: x1 + size,
    y1: y1,
    x2: x1 + size,
    y2: y2 > y1 ? y1 + size : y1 - size,
  }

  const l = { x: x1 + size, y: y2 > y1 ? y2 - size : y2 + size }
  const q2 = { x1: x1 + size, y1: y2, x2: x2, y2: y2 }

  return `
M ${x1} ${y1} 
Q ${q1.x1} ${q1.y1}, ${q1.x2} ${q1.y2} 
L ${l.x} ${l.y} 
Q ${q2.x1} ${q2.y1}, ${q2.x2} ${q2.y2} 
`
}

export const noop = () => {}

export const openUrl = (url?: string) => {
  if (!url) return
  window.open(url, '_blank', 'noopener,noreferrer')
}
export const shortenAddress = (address: string) =>
  address.length > 4
    ? `${address.slice(0, 4)}...${address.slice(
        address.length - 4,
        address.length
      )}`
    : ''

export const desiredNetwork =
  process.env.REACT_APP_MILKOMEDA_NETWORK === 'mainnet' ? 'mainnet' : 'testnet'

export const desiredChainId = desiredNetwork === 'mainnet' ? '0x7d1' : '0x30da5'

const tokensLinkPrefix =
  'https://raw.githubusercontent.com/defire-development/defire-frontend-assets/main/tokenList'

export const tokensUrl =
  desiredNetwork === 'mainnet'
    ? `${tokensLinkPrefix}/mainnet.json`
    : `${tokensLinkPrefix}/devnet.json?v=1`

export const desiredChain =
  desiredNetwork === 'mainnet'
    ? {
        chainId: '0x7d1',
        chainName: 'Milkomeda',
        nativeCurrency: {
          name: 'milkADA',
          symbol: 'milkADA',
          logoURI: '',
          decimals: 18,
        },
        blockExplorerUrl:
          'https://explorer-mainnet-cardano-evm.c1.milkomeda.com',
        rpcUrls: [],
      }
    : {
        chainId: '0x30da5',
        chainName: 'Milkomeda Testnet',
        nativeCurrency: {
          name: 'milkTADA',
          symbol: 'milkTADA',
          logoURI: '',
          decimals: 18,
        },
        blockExplorerUrl:
          'https://explorer-devnet-cardano-evm.c1.milkomeda.com',
        rpcUrls: [],
      }

export const getAddressExplorerUrl = (address: string) =>
  `${desiredChain.blockExplorerUrl}/address/${address}`

export const getTxExplorerUrl = (hash: string) =>
  `${desiredChain.blockExplorerUrl}/tx/${hash}`

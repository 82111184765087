import SmartHeader from 'components/smart/SmartHeader'
import Modals from 'components/smart/SmartModal/SmartModal'
import SmartSwap from 'components/smart/SmartSwap'
import Decimal from 'decimal.js'
import { Toaster } from 'react-hot-toast'
import { ReactComponent as Defs } from './defs.svg'

Decimal.set({ precision: 20, rounding: Decimal.ROUND_DOWN })

function App() {
  return (
    <div className="relative min-h-[100vh] overflow-hidden">
      <div className="absolute h-full w-full bg-bg bg-main bg-no-repeat" />
      <div className="m-auto w-[1000px] ds:w-[1200px]">
        <SmartHeader />
        <SmartSwap />
      </div>
      <Modals />
      <Toaster
        containerStyle={{
          top: 100,
        }}
        position="top-right"
        reverseOrder={false}
      />
      <Defs />
    </div>
  )
}

export default App

import ModalTitle from 'components/dumb/ModalTitle'
import { closeModal } from 'models/modal'
import { ReactComponent as FailIcon } from 'resources/images/icons/fail.svg'
import Button from './Button'

export default function TxFailureModal() {
  return (
    <div className="flex flex-grow flex-col items-center">
      <FailIcon className="mb-5 h-12 w-12" />
      <ModalTitle>Transaction failed</ModalTitle>
      <p className="mb-10 text-center text-lg leading-[1.3] text-white">
        Transaction will not succeed either due to price movement or transfer
        fee. Try increasing your slippage tolerance.
      </p>
      <Button className="w-full" onClick={() => closeModal()}>
        Close
      </Button>
    </div>
  )
}

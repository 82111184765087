import { useNavigate } from 'react-router-dom'
import { noop } from 'utils'

interface Props {
  children: React.ReactNode
  href?: string
  onClick?(): void
  className?: string
}

export default function TextLink({
  href,
  children,
  className = '',
  onClick = noop,
}: Props) {
  const navigate = useNavigate()
  const isInternal = href && href.startsWith('/')

  const handleClick = () => {
    if (isInternal) {
      navigate(href)
    } else {
      if (href) {
        window.open(href, '_blank', 'noopener,noreferrer')
      } else if (onClick) {
        onClick()
      }
    }
  }

  return (
    <div
      className={`
      ${className}
      inline cursor-pointer leading-[140%] text-red
      underline-offset-[0.25rem] hover:underline hover:decoration-red
      `}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}
